import React, { useState } from "react";
import "./header.css"
import arrowDown from "../assets/arrowDown.svg"

import { useVacancyStore } from "../pages/vacancies/store/store";
import { MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import NotificationsUtil from "../pages/notification/notification";


function Header (){


    const {vacancyCreatorFlag,setVacancyCreatorFlag} = useVacancyStore (state=>state);


    const handleArrowClick = (e)=>{
        e.preventDefault()
    }
 
      const handleMenuClick = (key) => {
        if (key === '0') {
          setVacancyCreatorFlag(true); // Set flag for Vacancy Creator
        } else if (key === '1') {
          setVacancyCreatorFlag(false); // Set flag for Responder or any other action
        }
      };
    
      const items = [
        {
          label: (
            <div
              onClick={() => handleMenuClick('0')} // Set flag when Vacancy Creator is clicked
              style={{
                color: vacancyCreatorFlag ? '#1f1f1f' : '#4D4D4D', // Conditional color
                backgroundColor: vacancyCreatorFlag ? '#D0D0D0' : 'transparent', // Conditional background color
                padding: '8px 16px',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            >
              Vacancy Creator
            </div>
          ),
          key: '0',
        },
        {
          label: (
            <div
              onClick={() => handleMenuClick('1')} // Set flag when Responder is clicked
              style={{
                color: '#4D4D4D',
                backgroundColor: !vacancyCreatorFlag ? '#D0D0D0' : 'transparent',
                padding: '8px 16px',
                borderRadius: '4px',
                cursor: 'pointer',
                width:'100%'
              }}
            >
              Responder
            </div>
          ),
          key: '1',
        },
      ];
    
      return (
        <div className="header-container">
          <div>
        
            <NotificationsUtil/>
          </div>
          <div style={{display:'flex',flexDirection:'row',gap:'5px'}}>
            <UserOutlined style={{fontSize:'20px'}}/>
            <div style={{display:'flex',flexDirection:"column",}}>
            <div style={{fontSize:'14px',fontWeight:'600'}}>Jane Cooper</div>
            <div style={{fontSize:'12px',color:'#7A7A7A'}}>{vacancyCreatorFlag?'Vacancy Creator':'Responder'}  </div>
            </div>
         
          </div>
          <Dropdown menu={{ items }} trigger={['click']}>
            <a onClick={handleArrowClick}>
              <Space>
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </div>
      );
    }
    
    export default Header;