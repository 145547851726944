import React, { useState } from "react";
import { Form, DatePicker, Select, Input, Button, Row, Col, Divider, Space,Checkbox } from "antd"
import { personalInfo, AddressInfo, Portfolio, Language,PermanentAddressInfo } from "./infoArray";
import CandidateImg from "../../../assets/CandidateInfo.svg"
import styles from "../../vacancies/vacancy_details/recruitmentOverview/recruit.module.css"
import styles1 from "./candidateProfile.module.css"
import ApllicationHistory from "./applicationHistory";
import ImageUpload from "../../../components/profileImageUpload";
import { useFirstRender } from "../../../custom-hooks/useFirstRender";
import { useCountryStateCityList } from "../../../custom-hooks/useCountryList";

const { RangePicker } = DatePicker;
const CandidateProfile = () => {
    const {countryName,getStatesByCountry,getCitiesByCountryandStates}=useCountryStateCityList()
    const {firstRender} =useFirstRender()
    const [selectedStateList,setSelectedStateList]=useState()
 

    if(firstRender){
        
        let countryLabel=countryName.map(ele =>{
            return ele?.name
        })
        personalInfo[7]["value"]=countryLabel
        AddressInfo[2]["value"]=countryLabel
        PermanentAddressInfo[2]["value"]=countryLabel
    }
  
    const onChange = (checkedValues) => {
        console.log('checked = ', checkedValues);
      };
    const Switch = (type, ele) => {
        switch (type) {
            case "input":
                return <Input placeholder={`Enter ${ele?.label}`} />;
            // This 'break' is redundant if you're using return, but included for your request.

            case "dropdown":
                return (
                    <Select onChange={(value) =>{
                        console.log(value);
                        if(ele.label.includes("Country")){
                            let stateName=getStatesByCountry(value).map(ele =>{
                                return ele?.name
                            })
                            console.log("Ss",stateName)
                            setSelectedStateList(stateName)
                         
                    
                        }
                    }}
            
                     showSearch placeholder={`Select ${ele?.label}`}>

                        {ele?.label?.includes("State")?selectedStateList?.map((eles, index) => (
                            <Select.Option key={index} value={eles}>
                                {eles}
                            </Select.Option>
                        )):
                        
                        ele?.value?.map((eles, index) => (
                            <Select.Option key={index} value={eles}>
                                {eles}
                            </Select.Option>
                        ))}
                    </Select>
                );


            case "date":
                return <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />;


            case "dateDuration":
                return <RangePicker style={{ width: "100%" }} />;

           case "checkbox":
            return <Checkbox.Group options={ele?.value} defaultValue={['Apple']} onChange={onChange} />
            default:
                return null; // Optionally handle the default case if type doesn't match any known type
        }

    }

    return (
        <div className="vacancies-wrapper">
            <div className={styles.container} style={{ gap: 20, padding: 24 }}>

                <div className={styles.card} style={{ width: "65%", padding: 16 }}>
                <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center",gap:8}}>
                <img src={CandidateImg} />
                    <div className={styles.modalTitle}>  Candidate Information</div>
                    </div>
                    <div className={styles1.center}>
                   <div style={{height:100,width:100,borderRadius:50,backgroundColor:"#EFDBFF",display:"flex",alignItems:"center",justifyContent:"center",fontWeight:600,fontSize:24}}> ER</div>
                        <ImageUpload imageUrl={""} cardType="circle" uploadButton={(<div style={{cursor:"pointer"}}>Upload</div>)}/>

                    </div>
                    <Divider />
                    <Form layout="vertical"
                   
                    >
                        <div className={styles.modalTitle} style={{ display: "flex", flexDirection: "row" }}>
                            <div className={styles1.lineHeight}> </div>Personal Info</div>
                        <Row gutter={16}>

                            {personalInfo?.map((ele, index) => (
                                <>

                                    <Col key={ele?.id} span={12}  >

                                        <Form.Item key={ele?.id} label={ele?.label} name={ele?.name}>
                                            {Switch(ele?.type, ele)}

                                        </Form.Item>
                                    </Col>

                                    {/* {(index === 5 || index === 8) && <Divider style={{marginBottom:32}}/>} */}

                                </>
                            ))}
                        </Row>
                        <div className={styles.modalTitle} style={{ display: "flex", flexDirection: "row" }}>
                            <div className={styles1.lineHeight}> </div>Current Address</div>
                        <Row gutter={16} >

                            {AddressInfo?.map((ele, index) => (
                                <>
                                    <Col key={ele?.id} span={12}  >

                                        <Form.Item  key={ele?.id}  label={ele?.label} name={ele?.name}>
                                            {Switch(ele?.type, ele)}

                                        </Form.Item>
                                    </Col>
                                </>
                            ))}
                        </Row>
                        <div className={styles.modalTitle} style={{ display: "flex", flexDirection: "row" }}>
                            <div className={styles1.lineHeight}> </div>Permanent Address</div>
                        <Row gutter={16} >

                            {PermanentAddressInfo?.map((ele, index) => (
                                <>

                                    <Col key={ele?.id} span={12}  >

                                        <Form.Item  key={ele?.id} label={ele?.label} name={ele?.name}>
                                            {Switch(ele?.type, ele)}

                                        </Form.Item>
                                    </Col>
                                </>
                            ))}
                        </Row>
                        <div className={styles.modalTitle} style={{ display: "flex", flexDirection: "row" }}>

                            <div className={styles1.lineHeight}> </div>Portfolio/Social Media</div>
                        <Row gutter={16} >

                            {Portfolio?.map((ele, index) => (
                                <>

                                    <Col key={ele?.id} span={12}  >

                                        <Form.Item key={ele?.id} label={ele?.label} name={ele?.name}>
                                            {Switch(ele?.type, ele)}

                                        </Form.Item>
                                    </Col>
                                </>
                            ))}
                        </Row>
                        <div className={styles.modalTitle} style={{ display: "flex", flexDirection: "row" }}>
                            <div className={styles1.lineHeight}> </div>Language</div>
                        <Row gutter={16} style={{ marginTop: 8 }}>

                            {Language?.map((ele, index) => (
                                <>

                                    <Col key={ele?.id} span={12}  >

                                        <Form.Item key={ele?.id} label={ele?.label} name={ele?.name}>
                                            {Switch(ele?.type, ele)}

                                        </Form.Item>
                                    </Col>
                                </>
                            ))}
                        </Row>
                        <Row>
                            <Col span={18}></Col>
                            <Col span={6}>
                                <Space>

                                    <Button htmlType="submit" type="primary">Save</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div className={styles.card} style={{ width: "30%", height: "90vh", padding: 24 }}>
                    <ApllicationHistory/>
                    
                </div>
            </div>
        </div>
    );

}
export default CandidateProfile