import { Tooltip } from "antd";
import React, { useEffect ,useState} from "react";


const StatusBar= ({ percent ,bars=[], stage }) => {
 

  
//   useEffect(() => {
//     const bars = document.getElementById(`bars-container-${index}`).childNodes;
//   console.log('bars',index,bars)
//     bars.forEach((bar, index) => {
//       const diff = ((percent - percentPerBar * index) / percentPerBar) * 100;
//       const barBackground = document.querySelector(
//         `.status-bar:nth-child(${index + 1})`
//       )?.style;
//       barBackground?.setProperty(
//         "--background",
//         `linear-gradient(to right, #13C2C2 ${diff}%, #E6FFFB ${diff}%)`
//       );
//     });

//     document.documentElement?.style.setProperty("--barsCount", barsCount);
//     document.documentElement?.style.setProperty("--barsGap", barsGap);
//   }, [percent, barsCount, percentPerBar, barsGap]);
const [isHovered, setIsHovered] = useState(false);
const [flag,setFlag]=useState();

const handleMouseEnter = (index) =>{ setIsHovered(true);setFlag(index)};
const handleMouseLeave = () => {setIsHovered(false);setFlag()};
const barsCount=bars?.length;
const totalCand=24;
  return (
    barsCount>0?
    <div className="bars-wrapper">
 <section className="bars-container"   style={{ gap: `${100 / barsCount}px` }}  >
   
   {bars.map((bar, index) => {
     const percentPerBar = 100 / barsCount;
   // Calculate the progress for each bar inside the return
   const diff = ((percent - percentPerBar * index) / percentPerBar) * 100;
   const baseWidth = 100 / barsCount; // Even split
  const adjustment = (barsCount-index+3 /totalCand) * 100 - baseWidth; // Adjust based on candidates
   const finalWidth= baseWidth + adjustment;

   return (
     <Tooltip placement="top"  zIndex={1} title={bar+` (${barsCount-index})`}>
     <div
       key={index}
       className="status-bar"
       style={{
           width: `${finalWidth}%`,
           
         background:
         
         isHovered && index==flag?`linear-gradient(to right,  #13C2C2 ${diff}%, #B5F5EC ${diff}%)` :
          `linear-gradient(to right,  #13C2C2 ${diff}%, #E6FFFB ${diff}%)`, // Set background inline
         
       }}

       onMouseEnter={()=>handleMouseEnter(index)}
      onMouseLeave={handleMouseLeave}
       
       >
       </div>
       </Tooltip>
   )
   
 })}
 </section>

    <div className="bars-status-info">
    {
      stage?<>
    
            <div className="bars-status-name">
            <div></div>
            <div>{stage??'-'}</div>
            </div>
            {/* <div className="bars-status-percent"> {percent??'-'}% </div> */}
            </>
          :<><i style={{color:'#4D4D4D',fontSize:'13px',fontWeight:'400'}}>No active stage</i></> }
            </div>
            
    </div>
   :
    <div className="bars-status-info" style={{height:'35px'}}> <i style={{color:'gray'}}>No Stages Available</i></div>

  );
};

export default StatusBar;
