import  {  useMemo, useCallback, useRef } from 'react'
import { Country, State, City }  from 'country-state-city';
// import countryList from 'react-select-country-list'

export function useCountryStateCityList() {
 
  const options = useMemo(() => Country.getAllCountries(), [])
  let StatesByCities=useRef(null)
  let cities=useRef(null)
  const getStatesByCountry =useCallback((countryName) =>{
   let {isoCode}= options.find(ele =>ele?.name===countryName)
  StatesByCities.current=State.getStatesOfCountry(isoCode)
  
  return StatesByCities.current
  },[options])
  const getCitiesByCountryandStates =useCallback((countryName,stateName) =>{
    let {isoCode}= options.find(ele =>ele?.name===countryName)
   StatesByCities.current=State.getStatesOfCountry(isoCode)
   let state=StatesByCities.current.find(ele =>ele?.name===stateName)
   cities.current=City.getCitiesOfState(isoCode,state.isoCode)
  
   return cities.current
   },[options])
  
  return {countryName:options,getStatesByCountry,getCitiesByCountryandStates}
}

