import ApplicationEmpty from "../../../assets/ApplicationEmpty.svg"
import ApplicationHistory from "../../../assets/ApplicationHistory.svg"
import styles from "../../vacancies/vacancy_details/recruitmentOverview/recruit.module.css"
import styles1 from "./candidateProfile.module.css"
import GreenStar from "../../../assets/greenStar.svg"
import { Divider } from "antd"
const arr = [{
  job: "Frontend Developer",
  id: "JV_2024/36",
  location: "Bengaluru/KA",
  jobType: "Full Time",
  type: "remote",
  stage: "Candidate Sourcing"
}, {
  job: "Frontend Developer",
  id: "JV_2024/36",
  location: "Bengaluru/KA",
  jobType: "Full Time",
  type: "remote",
  stage: "Interview Round 1"
},
]
const ApllicationHistory = () => {
  return (
    <div>
      <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center",gap:8}}>
      <img src={ApplicationHistory}/>
      <div className={styles.modalTitle}>Apllication History</div>
      </div>
      <div style={{marginTop:16}}>
      {arr.map((ele,index) => {
        return (
          <div className={styles1.applHistoryBox} >
            <div style={{
              display: "flex",
              justifyContent:"space-between",
             width:"100%"
            }}>
              <div>
            <div style={{ color: "#1890FF", fontSize: "16px",marginBottom:2 }}>
              {ele?.job}
              
              </div>
              {ele?.id}
            </div>
            <div className={styles1.greenBox} style={{height:30}}>
            <img src={GreenStar}/>
            84%
            </div>
            </div>
            <div style={{
              display: "flex", gap: 10,flexWrap:"wrap"

            }}>
              <div className={`${styles1.appHistoryBox} ${styles1.boxAlign}`}>
                {ele?.jobType}

              </div>
              <div className={`${styles1.appHistoryBox} ${styles1.boxAlign}`}>
                {ele?.location}

              </div>
              <div className={`${styles1.appHistoryBox} ${styles1.boxAlign}`}>
                {ele?.type}

              </div>
            </div>
            <div style={{display:"flex",gap:4}}>
             <div style={{height:10,width:10,borderRadius:5,backgroundColor:index===0?"#13C2C2":"gray",alignSelf:"center"}}/> 
             <div style={{fontWeight:600,color:index===0?"#13C2C2":"gray"}}>
            {ele?.stage.toUpperCase()}
            </div>
            </div>
           <Divider/>
          </div>
        )
      })}
      </div>
      {/* <div className={`${styles.dashedBox} ${styles1.center}`} style={{ marginTop: 24, height: "580px", }}>
        <div>
          <img src={ApplicationEmpty} style={{ height: "30%", width: "100%" }} />
          <div className={styles.modalTitle} style={{ textAlign: "center" }}>No Data to display</div>
          <div>This section is currently empty</div>
        </div>
      </div> */}

    </div>
  )
}

export default ApllicationHistory