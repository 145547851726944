import React from "react";
import "./customButton.css";
import { useResumeScreeningStore, useStore } from "./store/store";

const CustomButton = ({ text, img, onClick, variant }) => {
  const { progress } = useResumeScreeningStore((state) => state);

  return (
    <button
      className={`button-container-${variant}`}
      onClick={() => {
        if (progress === 100) {
          onClick();
        }
      }}
      disabled={progress !== 100} // Disable the button if progress is not 100
      style={{
        background: text === "Set Weightage" ? "transparent" : "",
        cursor: progress === 100 ? "pointer" : "not-allowed", // Optional styling for disabled state
        opacity: progress === 100 ? 1 : 0.6, // Optional styling for disabled state
      }}
    >
      <img src={img} alt="Icon" />
      {text}
    </button>
  );
};

export default CustomButton;
