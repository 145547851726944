import React, { useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Flex, message, Upload } from 'antd';
const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    message.error('Image must smaller than 10MB!');
  }
  return isJpgOrPng && isLt2M;
};
const ImageUpload = ({imageUrls,onUploadDone,props=null,uploadButton}) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(imageUrls);
  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        console.log("img",url)
        setLoading(false);
        setImageUrl(url);
        onUploadDone(url,info)
      });
    }
  };
//   const uploadButton = (
//     <button
//       style={{
//         border: 0,
//         background: 'none',
//       }}
//       type="button"
//     >
//       {loading ? <LoadingOutlined /> : <PlusOutlined />}
//       <div
//         style={{
//           marginTop: 8,
//         }}
//       >
//         Upload
//       </div>
//     </button>
//   );

  return (
 
     
      <Upload
      {...props} //pass the props of antd as required,commented below for reference
        // name="avatar"
        // listType={cardType==="circle"?"picture-circle":"picture-card"}
        // className="avatar-uploader"
         showUploadList={true}
        action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
        // beforeUpload={beforeUpload}
       
        onChange={handleChange}
      >
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="avatar"
            style={{
              width: '100%',
            }}
          />
        ) : (
          uploadButton
        )}
      </Upload>
    
  );
};
export default ImageUpload;
