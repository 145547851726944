import React, { useState } from "react";
import HomeIcon from "../assets/states.svg"
import Home from "../landing/home";
import VacanciesIcon from "../assets/Vacancies.svg"
import UnionIcon from "../assets/Union.svg"
import "./sidebar.css"
import { Link , useLocation} from "react-router-dom";
import { useStore } from "./store/store";
import logo from '../assets/logo.svg'

function SideBar(){

    //const [visible, setVisible] = useState(false);
    const location = useLocation();
    const {visible,setVisible} = useStore(state=>state);

    const handleClick =()=>{
        setVisible(!visible);
    }
    const checkActive=(path)=>{
        console.log("pathname",location.pathname)
        return   location.pathname.includes(path)
    }

    return (
        <div style={{display:'flex',flexDirection:'row',height:'100%',position:'relative'}}>
        <div className="sidebar-container">
   
            <div >
            <img src={logo} alt="VacanciesIcon" />
               <Link  to="/" ><img src={HomeIcon} alt="HomeIcon" /></Link> 
               <img src={VacanciesIcon} alt="VacanciesIcon" onClick={handleClick }/>
               
            </div>
            <div
            onClick={handleClick}
             style={{width:'26px',height:'26px',
             backgroundColor:'rgba(255,255,255,0.1)'
             ,borderRadius:'100%',alignSelf:'center',
             justifyContent:'center',cursor:'pointer'}}>
                <img src={UnionIcon} alt="UnionIcon"  style={{rotate:visible?'180deg':'0deg'}}/>

            </div>

        </div>
        {(visible) &&(
            <div className="sidebar-container-expanded">
                <h3>Hiring Central</h3>
                <h5>MAIN MENU</h5>
             
                <Link  to="/dashboard" className={`navlink ${checkActive('/dashboard')?'navlink-active':''}`} onClick={handleClick}>  <li>Dashboard</li></Link>
                  
                <Link  to="/vacancies" className={`navlink ${checkActive('/vacancies')?'navlink-active':''}`}  onClick={handleClick}>   <li>Vacancies</li></Link>
                <Link  to="/resume-library" className={`navlink ${checkActive('/resume-library')?'navlink-active':''}`}  onClick={handleClick}>   <li>Resume Library</li></Link>
                 
                <Link  to="/interviews" className={`navlink ${checkActive('/interviews')?'navlink-active':''}`}  onClick={handleClick}>  <li>Interviews</li></Link>
                  <h5>SETTINGS</h5>
                <Link  to="/users" className={`navlink ${checkActive('/users')?'navlink-active':''}`}  onClick={handleClick}>    <li>Users</li></Link>
                <Link  to="/hackathon-library" className={`navlink ${checkActive('/hackathon-library')?'navlink-active':''}`}  onClick={handleClick}>     <li>Hackathon Library</li></Link>
                <Link  to="/feedback-studio" className={`navlink ${checkActive('/feedback-studio')?'navlink-active':''}`}  onClick={handleClick}>     <li>Feedback Studio</li></Link>
                <Link  to="/email-template-builder" className={`navlink ${checkActive('/email-template-builder')?'navlink-active':''}`}  onClick={handleClick}>      <li>Email Template Builder</li></Link>
                
                    <Link to="/configuration" className={`navlink ${checkActive('/configuration')?'navlink-active':''}`}  onClick={handleClick}>    <li>Configurations</li></Link>
             
            </div>
        )
        }
        </div>
    );
}

export default SideBar;