import React, { useEffect, useState } from "react";
import CustomTabs from "../CustomTabs";

import { useVacancyStore } from "../store/store";
import JobOverview from "./job_overview/jobOverview";
import { ArrowLeftOutlined, DeleteFilled, DeleteOutlined, DownloadOutlined, EllipsisOutlined, ExclamationCircleFilled, ShareAltOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ActivitiesPage from "./activities/activity";
import RecruitmentOverview from "./recruitmentOverview/recruitmentOverview";
import activitiesIcon from "../../../assets/activitiesIcon.svg"
import activitiesIconInactive from "../../../assets/activitiesIconInactive.svg"
import RecruitactiveImage from "../../../assets/recruitOverview.svg"
import RecruitInactiveImage from "../../../assets/recruitInactive.svg"
import jobOverviewActive from '../../../assets/icons/job-overview-active.svg';
import jobOverviewInactive from '../../../assets/icons/job-overview-inactive.svg';
import './styles.css'
import { Dropdown, message, Modal } from "antd";
import { getJobOverview, deleteVacancyRequest } from "../Action/action";
function VacancyDetails ({tab})
{

    const navigate=useNavigate();
    const {vacancyData, setVacancyData,vacancyCreatorFlag} = useVacancyStore(state=>state);
   const previousUrl=localStorage.getItem('previousUrl')
    const {id}=useParams();
    const [invoke,setInvoke]=useState(false);
    const { confirm } = Modal;
  
    const tabs = [
        { 
          name: "Job Overview", 
          content: <JobOverview data={invoke}/>,
          url:`/vacancies/${id}`,
          style:{
            width:'160px',
            marginLeft:'0px',
          },
          iconActive:<img src={jobOverviewActive}/>,
          iconInactive: <img src={jobOverviewInactive}/>
        },
        { 
          name: "Recruitment Overview", 
          content: <RecruitmentOverview/>,
          url:`/vacancies/${id}/recruitment-overview`,
          style:{
            width:'160px',
            marginLeft:'320px',
          },
          iconActive:<img src={RecruitactiveImage}/>,
          iconInactive: <img src={RecruitInactiveImage}/>
        },
        { 
          name: "Activities", 
          content:   <ActivitiesPage/>,
          url:`/vacancies/${id}/activity`,
          style:{
            width:'100px',
            marginLeft:'420px',
          },
          iconActive:<img src={activitiesIcon}/>,
          iconInactive: <img src={activitiesIconInactive}/>
        },
     
      ];


      useEffect(()=>{
        const params={
          jobId:id,
          role:vacancyCreatorFlag?'Creator':"Responder"
        }
          getJobOverview(params,(response,error)=>{
            if (error) {
              console.error("Error posting vacancy:", error);
            } else {
            
              setVacancyData(response?.jobs?.[0]);
              setInvoke(!invoke)
          
           
      
            }
          })
     

      },[vacancyCreatorFlag,id])

  
      const handleDelete = ()=>{
    
        
        deleteVacancyRequest(id,(response,error)=>{
          if (error) {
            console.error("Error deleting vacancy:", error);
          } else {
            console.log("Vacancy deleted successfully:", response);
            navigate('/vacancies')
          
          }
        });
      }

      const showConfirm = () => {
        confirm({
          title: 'Are you sure delete this vacancy?',
          icon: <ExclamationCircleFilled />,
          content: 'Deleting vacancy will be permanently removed. Do you still want to proceed?',
          onOk() {
            handleDelete()
          },
          onCancel() {
            
          },
        });
      };
 

    return (
   
        <div className="vacancies-wrapper">
     
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',padding:'20px',}}>
    
        <div style={{display:'flex',flexDirection:'row',gap:'10px'}} >
        <div style={{marginTop:'4px',width:'30px',cursor:'pointer'}} onClick={()=>{
            navigate(previousUrl||'/vacancies/active');
        }}>
            <ArrowLeftOutlined style={{fontSize:'18px'}}/>
        </div>
        <div>
        {!vacancyData?.jobTitle?
        <>
        <h3 className="animated-bg animated-bg-text" style={{width:'220px',height:'18px'}}></h3>
        <br></br>
             <p className="animated-bg animated-bg-text" style={{width:'150px',height:'15px'}}></p>
        </>
          
          :      <>
          <h3 style={{margin:'3px 0px'}} >{vacancyData?.jobTitle||'-'}</h3>
          <p style={{margin:0}}><Link to={'/vacancies/active'} style={{color:'black'}} className="vacancy-link">Vacancies</Link> / {vacancyData?.status||'-' } / {vacancyData?.jobCode||'-'} </p>

          </> 

        }
    
        
        </div>
        </div>
      <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',gap:'10px'}}>
      <div className="share-jd" onClick={()=>{
    const url=window.location.href;
     navigator.clipboard.writeText(url)
        .then(() => {
          // alert('Content copied to clipboard!');
     
          message.success("Vacancy link copied to clipboard.")

        })
        .catch(err => {
          // console.error('Failed to copy: ', err);
        });
      }} ><ShareAltOutlined /></div>
      <div className="more-jd"> 
      <Dropdown
       
    menu={{
      items:[
        vacancyData?.status!='Active'? {
    label: <div className="delete-jd" onClick={showConfirm}> <DeleteFilled/> Delete</div>,
    key: '1',
  }:null,
  {
    label: <div className="export-jd"> <DownloadOutlined/> Export</div>,
    key: '2',
  },

]
   
    }}
    trigger={['click']}
  
  >
       <EllipsisOutlined  />
       </Dropdown>
       
       </div>
    
      </div>
      
            </div>
         <CustomTabs tabs={tabs} activeTab={tab}/>
         

    
        
    </div>
       
    );
}

export default VacancyDetails;