import { Button, Modal,Select } from "antd"
import styles from "./recruit.module.css"
import  RecruitEmpty from "../../../../assets/recruitmentEmpty.svg"
import  Close from "../../../../assets/clearIcon.svg"
import Add from "../../../../assets/addPlus.svg"

import { memo, useCallback, useState } from "react";
const options = [];
for (let i = 10; i < 36; i++) {
  options.push({
    label: `Baron ltd${i}`,
    value: JSON.stringify({
        name:`Baron ltd${i}`,
        id:i,
        logo:"BL"
    }),
  });
}

const RecruitAddModal = memo((props) =>{
    const [selectedArray,setSelectedArray]=useState([])
    const [addedArray,setAddedArray]=useState([])
    const handleChange = useCallback((value) => {
        console.log(`selected ${value}`);
         setSelectedArray(value)
      },[]);
 return(
    <Modal 
    open={props?.recruitAddModal!==""} 
    onCancel={() =>{props.setEditRecruitModal("")}} 
    onClose={() =>props.setEditRecruitModal("")}
   
    footer={false}
   
    >
    <div style={{display:"flex",flexDirection:"column"}}>
      <div className={styles.modalTitle} style={{marginBottom:16,fontWeight:"bold"}}>
        {props?.recruitAddModal==="partner"?"Recruitment Partner":"Recruitment Team"}
        </div>
      <div style={{display:"flex",flexDirection:"row"}}>
      <Select
      mode="multiple"
      allowClear
      style={{
        width: '80%',
      }}
      placeholder="Search name"
      value={selectedArray}
      onChange={handleChange}
      options={options}
    />
      <button
      className={styles.addBtn}
      style={{marginLeft:"2%",display: "flex", 
      alignItems: "center", 
      justifyContent: "center",cursor:"pointer" }}
      onClick={() =>{
        let array=addedArray.concat(selectedArray)
        setAddedArray(array)
        setSelectedArray([])
      }}>
        <img src={Add} height={"60%"} style={{marginRight:"4px"}} /> 
        {" " + " "} Add
        </button>
        </div>
      {addedArray.length>0?<div style={{height:"480px",overflowY:"scroll"}}>
       {addedArray.map(ele =><div className={styles.box} style={{height:"72px",display:"flex",justifyContent:"space-between",alignItems:"center",marginTop:16,padding:4}}>
        <div>{JSON.parse(ele).logo}  {" "}
        {JSON.parse(ele).name}</div>
        <img onClick={() =>{
       
            setAddedArray(addedArray.filter(eles=>JSON.parse(eles).id!==JSON.parse(ele).id))
        }} src={Close} style={{cursor:"pointer"}}/>
        </div>
         )}
      </div>:
    <div className={styles.dashedBox} style={{justifyContent:"center",display:"flex",alignItems:"center",marginTop:8,height:"480px"}}>
        <div>
      <img src={RecruitEmpty} height={"100px"} width={"100px"}/>
      <div className={styles.modalTitle}>Its Empty Here</div>
      {/* <div style={{textAlign:"center"}}> There are no team {props?.recruitAddModal==="partner"?"partners":"members"} at this moment</div> */}
      </div>
    </div>}
    <Button onClick={() =>{props.setEditRecruitModal("")}} style={{color:"#4D4D4D",borderColor:"#4D4D4D",alignSelf:"flex-end",marginTop:16}}>Done</Button>
    </div>
    </Modal>
 )
})

export default RecruitAddModal