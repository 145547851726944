

export const personalInfo=[

    {
        id:1,
        label:"Name",
        type:"input"
    },
    {
        id:2,
        label:"Job Title",
        type:"input"
    },
    {
        id:3,
        label:"Email Id",
        type:"input"
    },
    {
        id:4,
        label:"Mobile Number",
        type:"input"
    },
    {
        id:5,
        label:"Alternate mobile number",
        type:"input"
    },
    {
        id:6,
        label:"Gender",
        type:"input"
    },
    {
        id:7,
        label:"Date of Birth",
        type:"date"
    },
    {
        id:8,
        label:"Nationality/Citizenship",
        type:"dropdown"
    },
    {
        id:9,
        label:"Martial Status",
        value:["Single/Unmarried","Married","Widowed","Divorced","September","Others"],
        type:"dropdown"
    },
    {
        id:10,
        label:"Differentially-abled",
        value:["Yes","No"],
        type:"dropdown"
    },
    {
        id:11,
        label:"Taken a career break",
        value:["Yes","No"],
        type:"dropdown"
    }
]

export const AddressInfo=[
    {
        id:1,
        label:"Address 1",
        type:"input"
    },
    {
        id:2,
        label:"Address 2",
        type:"input"
    },
    {
        id:3,
        label:"Country/Region",
        type:"dropdown"
    },
    {
        id:4,
        label:"State/Region/Province",
        type:"dropdown"
    },
    
    {
        id:5,
        label:"City",
        type:"dropdown"
    },
    {
        id:6,
        label:"Zip/Postal Code",
        type:"dropdown"
    }
]
export const PermanentAddressInfo=[
    {
        id:1,
        label:"Address 1",
        type:"input"
    },
    {
        id:2,
        label:"Address 2",
        type:"input"
    },
    {
        id:3,
        label:"Country/Region",
        type:"dropdown"
    },
    {
        id:4,
        label:"State/Region/Province",
        type:"dropdown"
    },
    
    {
        id:5,
        label:"City",
        type:"dropdown"
    },
    {
        id:6,
        label:"Zip/Postal Code",
        type:"dropdown"
    }
]
export const Portfolio=[
    {
        id:1,
        label:"Portfolio",
        type:"input"
    },
    {
        id:2,
        label:"LinkedIn",
        type:"input"
    },
    {
        id:3,
        label:"GitHub",
        type:"input"
    },
    {
        id:4,
        label:"Youtube",
        type:"input"
    },
    {
        id:5,
        label:"Instagram",
        type:"input"
    },
    {
        id:6,
        label:"Others",
        type:"input"
    },
]
export const Language = [
    {
        id:1,
        label:"Language",
        type:"input"
    },
    {
        id:2,
        label:"Proficiency",
        type:"dropdown"
    },
    {
        id:3,
        label:"",
        type:"checkbox",
        value:[
            {
              label: 'Read',
              value: 'Read',
            },
            {
              label: 'Write',
              value: 'Write',
            },
            {
              label: 'Speak',
              value: 'Speak',
            },
          ]
    },

]