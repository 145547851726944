import React, { useEffect, useState } from "react";
import "./activities.css"
import SummaryCard from "./summaryCard";
import axios from "axios";
import activitiesNotes from "../../../../assets/activitiesNotes.svg"
import { Select } from "antd";
import { Timeline } from "antd/lib";
import CustomTimeline from "./timelineItem";
import CustomCarousel from "./customCarousel";
import NewCard from "./newCard";
import ResumeCandidate from "./resumeCandidates";
import CustomBarChart from "./customBarChart";
import { getVacancyActivities } from "../../Action/action";
import { useActivitiesStore } from "../../store/store";
import { useParams } from "react-router-dom";
import { DatePicker } from "antd";

const ActivitiesPage = ()=>{

  const {id} = useParams();

    const {activitiesData, setActivitiesData}  = useActivitiesStore(state=>state);
    const [selectedOption, setSelectedOption] = useState({ month: "", year: "" });

    const handleChange = (date) => {
      if (date) {
        setSelectedOption({
          month: date.month() + 1, // Month is 0-indexed
          year: date.year(),
        });

      } else {
        setSelectedOption({ month: "", year: "" });
      }
    };


        const months = Array.from({ length: 12 }, (_, i) => ({
          value: String(i + 1).padStart(2, '0'),
          label: new Date(0, i).toLocaleString('default', { month: 'long' }),
        }));

     const handleLoadMore=(section)=>{
     console.log(section,"checkloadmore")
      let params = {
        page:Number(section?.data?.page)+1,
        year: "2024",
        month: "12",

      }
      getVacancyActivities(params,(response,error)=>{
        if (error) {
          console.error("Error geting activities:", error);
        } else {
        
          setActivitiesData(response?.result)
          
          console.log("Activities check :",activitiesData);
        }
      })
     }


  const slides= [
<NewCard/>,<ResumeCandidate/>
  ];


  useEffect(()=>{
    let params = {
        jobId: id,
        year: selectedOption?.year,
        month: selectedOption?.month
    }
    getVacancyActivities(params,(response,error)=>{
        if (error) {
          console.error("Error geting activities:", error);
        } else {
        
          setActivitiesData(response?.result)
          
          console.log("Activities check :",activitiesData);
        }
      })
  },[selectedOption])

  const formattedData = {};
  for (const [key, value] of Object.entries(activitiesData)) {
    // Split the month and year
    const [month, year] = key.split('-');
    
    // Create a date string formatted as "Month Year"
    const date = new Date(year, month - 1);
    const formattedDate = date.toLocaleString('en-US', { month: 'long', year: 'numeric' });
    
    // Assign the activities (or other data) to the new formatted key
    formattedData[formattedDate] = value || [];
  }

  const sections = Object.entries(formattedData)?.map(([key, value]) => ({
    label: key,
    data: value
  }));
  
  console.log(sections?.data,"ACTIVITIES")

    return(
 
                <div className="activity-subcontainer">
                    <div className="activity-subcontainer-subcontainer">
                        <div>
                            <div>
                                <img src={activitiesNotes} alt="notesIcon"/>
                                <div >Activities</div>
                            </div>
                            <div>
                                Jump to:
                             
                                   <DatePicker
                                      picker="month"
                                      onChange={handleChange}
                                      placeholder="Select Month and Year"
                                      style={{width:'200px'}}
                                    />
                             
                            </div>
                        </div>

                    <div>
                        
                            {sections.map((section, index) => (
                                <div key={index} className="activities-timeline">
                                    <div>{section.label}</div>
                                    <CustomTimeline 
                                        options={section?.data}
                                        onLoadMore={async (nextPage) => {
                                          handleLoadMore(section);
                                            // const response = await fetch(`/api/getActivities?page=${nextPage}`);
                                            // return await response.json();
                                          }}
                                          type="activities"
                                    />
                                </div>
                            ))}
                            {/* </>
                            } */}
                     </div>  
                        
                    </div>
                    <div>
                        <SummaryCard/>
                       <div style={{width:'400px'}}> <CustomCarousel slides={slides}/></div>
                    
                    </div>
                  
                </div>
    
    );

}

export default ActivitiesPage