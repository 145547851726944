import React, { useState } from 'react';
import { DatePicker, Tooltip } from 'antd';
import dayjs from 'dayjs';
import "./createRequests.css";

const MyDatePicker = ({ date, handleAnticipatedDate }) => {
  const [showWarning, setShowWarning] = useState(false);

  const disablePastDates = (current) => {
    // Disable dates before today
    return current && current <= dayjs().startOf('day');
  };

  const handleDateChange = (value) => {
    if (value) {
      setShowWarning(false); // Hide warning if a valid date is selected
      handleAnticipatedDate(value.format("YYYY/MM/DD")); // Send formatted date
    } else {
      // Handle clearing of the date
      handleAnticipatedDate(null);
    }
  };

  return (
    
      <DatePicker
        disabledDate={disablePastDates}
        defaultValue={date ? dayjs(date, "YYYY/MM/DD") : null} // Prevent 1970-01-01
        placeholder="Select Date"
        dropdownStyle={{ zIndex: 1000000 }}
        className={`requests-datepicker`}
        onChange={handleDateChange}
        allowClear
      />
    
  );
};

export default MyDatePicker;
