import React, { useEffect, useState } from "react";
import "./vacancies.css"
import CustomTabs from "./CustomTabs";
import CreateRequests from "./CreateRequests";
import Jobs from "./jobs/Jobs";
import addButtonIcon from "../../assets/addButtonIcon.svg"
import { useVacancyStore } from "./store/store"
import { useStore } from "../../sidebar/store/store.js";
import Analytics from "./analytics/analytics.js";
import exportIcon from "../../assets/exportIcon.svg"
import {useGet} from "../../custom-hooks/useApi.js"
import { getJobsCount, getJobCode } from "./Action/action.js";

function Vacancies ({tab})
{
    const {visible,setVisible} = useStore(state=>state);
    const {creatorFormModal, setCreatorFormModal,submittedData,
      responseData,vacancyCreatorFlag,responderFormModal,setResponderFormModal,
      setActiveTab,activeTab,jobCode,setJobCode,resetFilters,jobsResponse

    } = useVacancyStore(state=>state);

    const { data, error } = useGet("/api/hiring/jobCode");
    const [invoke,setInvoke]=useState(false);
    const [activeCount,setActiveCount]=useState(0);
    const [inactiveCount,setInactiveCount]=useState(0);
    useEffect(()=>{
        setActiveTab(tab);

        getJobsCount({  role:vacancyCreatorFlag?'Creator':"Responder"},(res,err)=>{
          
           setActiveCount(res?.active||0);
          
          
            setInactiveCount(res?.inactive||0);
     
        })
     
    },[tab,vacancyCreatorFlag,jobsResponse])
    useEffect(()=>{
      resetFilters()
    },[activeTab])
    const tabs = [
        { 
          name: "Requests", 
          content: <CreateRequests/>,
          url:'/vacancies',
          style:{
            width:'100px',
            marginLeft:'0px',
    
          }
        },
        { 
          name: "Active", 
          content: <Jobs status={"active"} />,
          url:'/vacancies/active',
          count:activeCount,
          style:{
            width:'100px',
            marginLeft:'200px',
          }
        },
        { 
          name: "In-active", 
          content:    <Jobs status={"inactive"} />,
          url:'/vacancies/in-active',
          count:inactiveCount,
          style:{
            width:'100px',
            marginLeft:'300px',
          }
        },
        {
          name: "Analytics & Reports",
          content: <Analytics/>,
          url:'/vacancies/analytics',
          style:{
            width:'160px',
            marginLeft:'460px',
          }
        },
        // {
        //   name: "Settings",
        //   content: <div>
        //     <h3>Automatically generate job vacancy code</h3>
        //     <p>Our system generates a unique code for every new job vacancy, ensuring easy reference throughout the hiring cycle and reducing the chance of duplicates.</p>
        //     </div>,
        //   style:{
        //         width:'160px',
        //         marginLeft:'620px',
        //       }
       
        // }
      ];
    const filteredTabs = vacancyCreatorFlag ? tabs.slice(0, 3) : tabs;
    const handleClick = (type)=>{
      if(type==="Creator"){
      setCreatorFormModal(!creatorFormModal);
      }
      if(type==="Responder"){

        getJobCode({},(res,err)=>{
          
          setJobCode(res?.jobCode);
         
         
          
    
       })
        setResponderFormModal(!responderFormModal)
      
         
        
      }
    }



  


    return (
       
        <div className="vacancies-wrapper">
     
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',padding:'20px'}}>
        <div >
            <h3 style={{margin:'3px 0px',fontSize:'20px',fontWeight:700}}>Vacancies</h3>
            <p style={{margin:0,fontSize:'14px',fontWeight:500,color:'#4D4D4D'}}>Manage job vacancies and optimise your recruitment process for maximum efficiency</p>
        </div>
            {(responseData?.vacancies?.length>0&&vacancyCreatorFlag && activeTab==="Requests")?
                <div>
                  <button id="raiseReqForm" style={{cursor:'pointer'}} type="button" onClick={()=>{
                    handleClick("Creator")}}  
                      className="dashed-box-button"> 
                    <img src={addButtonIcon} />
                    <div>Raise a request</div>
                  </button>
                </div>:
                vacancyCreatorFlag===false &&  activeTab==="Requests"?
                <div>
                  <button id="raiseReqForm" style={{cursor:'pointer'}} type="button" onClick={()=>{
                    handleClick("Responder")
                  }}  
                      className="dashed-box-button"> 
                    <img src={addButtonIcon} />
                    <div>Create vacancy</div>
                  </button>
                </div>:
                activeTab==="Analytics & Reports"?
                <div>
                <button id="exportReport" style={{cursor:'pointer'}}> 
                  <img src={exportIcon}/>
                 <div> Export report</div>
                </button>
              </div>
                :null}
            </div>
            {<CustomTabs tabs={tabs} activeTab={tab}/>}
         

    
        
    </div>
       
    );
}

export default Vacancies;