import React, { useEffect, useRef } from "react"

export const useFirstRender = () =>{

    let ref=useRef(true)

    useEffect(() =>{
    ref.current=false
    },[])
  return {firstRender:ref.current}
}