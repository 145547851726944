import React from "react";
import { Modal, Form, message, Input, Button } from "antd";
import { useNavigate } from "react-router-dom";
import "./customWeights.css";
import { useResumeScreeningStore } from "../../hiring/store/store";

const CustomWeightage = ({ isModalOpen, handleOk, handleCancel }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const {jobTitle, yearsOfExperience, jobDescripton, uploadedFile,analyseModal,setAnalyseModal} = useResumeScreeningStore(state=>state);

  // Function to validate the sum of the fields
  const validateSum = (values) => {
    const total = Object.values(values).reduce(
      (acc, value) => acc + Number(value), // Convert string to number
      0
    );
    if (total !== 1) {
      message.error("The total percentage must sum up to 100.");
      return false;
    }
    return true;
  };

  // Submit handler
  const onFinish = async (values) => {
    // Convert all values to numbers and scale them by dividing by 100
    const numericValues = Object.fromEntries(
      Object.entries(values).map(([key, value]) => [key, Number(value) / 100])
    );
    const customWeights = Object.values(numericValues).join(",");
  
    // Validate that the sum of numeric values equals 1 (or 100%)
    const total = Object.values(numericValues).reduce((acc, val) => acc + val, 0);
    if (Math.abs(total - 1) > 0.01) { // Allow for floating-point inaccuracies
      message.error("The percentages must add up to 100%");
      return;
    }
    console.log("Saved data:", numericValues); // Log the data as numbers
    message.success("Data saved successfully!");
  
    
    handleOk(false);
    //analyseModal(true);
  
    // Prepare form data
    const formData = new FormData();
    formData.append("cv_file", uploadedFile); // Attach the uploaded file
    formData.append("role_name", jobTitle); // Attach the job title
    formData.append("years_of_experience", yearsOfExperience); // Attach years of experience
    formData.append(
      "job_description",
      jobDescripton);
    formData.append("custom_weights", customWeights);
    try {
      // API call
      const response = await fetch("https://jd.aulas.in/ats-score", {
        method: "POST",
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error("Failed to analyze the resume.");
      }
  
      const result = await response.json();
      console.log("API Response:", result);
  
      // Simulate progress and navigation
      setTimeout(() => {
        // setProgress(100);
        // setAnalyseModal(false);
        navigate("/results", { state: result }); // Pass the result to the results page
      }, 5000);
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while analyzing the resume.");
     // setAnalyseModal(false);
    }
  
    
  };
  

  const handleModalClose = () => {
    handleCancel(false);
    form.resetFields(); // Clear the form when the modal is closed
  };

  const fields = [
    { name: "hardSkills", label: "Hard Skills" },
    { name: "softSkills", label: "Soft Skills" },
    { name: "experience", label: "Experience" },
    { name: "formatScore", label: "Format Score" },
    { name: "salesIndex", label: "Sales Index" },
  ];

  return (
    <Modal
      title="Set Weightage"
      open={isModalOpen}
      onCancel={handleModalClose}
      footer={[
        <Button key="back" onClick={handleModalClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          Save data
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="horizontal" // Use horizontal layout
        onFinish={onFinish}
        initialValues={{
          hardSkills: 0,
          softSkills: 0,
          experience: 0,
          formatScore: 0,
          salesIndex: 0,
        }}
        labelCol={{ span: 10 }} // Adjust label column width
        wrapperCol={{ span: 14 }} // Adjust input field column width
      >
        {fields.map((field) => (
          <Form.Item
            key={field.name}
            name={field.name}
            label={field.label}
            rules={[
              { required: true, message: `Please input a percentage for ${field.label.toLowerCase()}` },
            ]}
          >
            <Input
              type="number"
              placeholder={`Enter percentage for ${field.label}`}
              suffix="%"
            />
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};

export default CustomWeightage;
