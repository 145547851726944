import React, { useRef, useState } from 'react';
import fileIcon from "../../../assets/fileIcon.svg"
import closeIcon from "../../../assets/closeIcon.svg"
import deleteIcon from "../../../assets/deleteIcon.svg"
import uploadIcon from "../../../assets/uploadIcon.svg"
import { useResumeScreeningStore } from '../../hiring/store/store';

const ClickToUploadWithProgress = ({ onUpload }) => {
  const fileInputRef = useRef();
  //const {uploads, setUploads} = useResumeScreeningStore(state=>state);
  const [uploads, setUploads] = useState([]);
  const {setProgress} = useResumeScreeningStore(state=>state);
  
  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const allowedTypes = [
      'application/pdf',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];
    const maxFileSize = 25 * 1024 * 1024; // 25MB in bytes

    if (files && files.length > 0) {
      const file = files[0];

      // Validate file type
      if (!allowedTypes.includes(file.type)) {
        alert('Invalid file type. Please upload a PDF, Excel, Word, or PowerPoint file.');
        return;
      }

      // Validate file size
      if (file.size > maxFileSize) {
        alert('File size exceeds 25MB. Please upload a smaller file.');
        return;
      }

      // Add file to upload queue
      const newUpload = { file, progress: 0 };
      setUploads((prev) => [...prev, newUpload]);
    
      simulateUpload(newUpload);
    }
  };

  const simulateUpload = (upload) => {
    const total = 100; // Simulating 100% upload
    let progress = 0;

    const interval = setInterval(() => {
      progress += 10; // Increment progress
      setUploads((prev) =>
        prev.map((u) =>
          u.file === upload.file ? { ...u, progress: progress > total ? total : progress } : u
        )
      );
    

      if (progress >= total) {
        clearInterval(interval);
        setProgress(100);
        // Notify the parent that the upload is complete by passing the file
        onUpload(upload.file);
      }
    }, 500); // Simulates server response delay
  };

  const handleDelete = (file) => {
    setUploads((prev) => prev.filter((upload) => upload.file !== file));
  };

  return (
    <div>
      <button
        onClick={handleClick}
        style={{
          padding: '10px 20px',
          backgroundColor: '#fff',
          color: '#1890FF',
          border: '1px solid #1890FF',
          borderRadius: '5px',
          cursor: 'pointer',
          textAlign:'left',
          width:'100%',
          display:'flex',
          flexDirection:'row',
          gap:'8px',
          alignItems:'center'
        }}
      >
        <img src={uploadIcon} alt="uploadIcon"/>
        <div>Click to Upload</div>
      </button>
      <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between', width:'100%',color:'#7A7A7A', fontSize:'10px',marginTop:'4px'}}>
              <div>Supported formats: PDF, XLS, XLSX, DOC, DOCX, PPT, PPTX</div>
              <div>Maximum size: 25MB</div>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        accept=".pdf,.xls,.xlsx,.ppt,.pptx,.doc,.docx" // Restrict file extensions
      />

      <div style={{ marginTop: '20px' }}>
        {uploads?.map((upload, index) => (
          <div
            key={index}
            style={{
              // marginBottom: '15px',
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '5px',
              backgroundColor: '#fff',
              color: '#1890FF',
              fontSize:'14px',
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <div>
                <div style={{                    
                        display:'flex',
                        width:'20px',
                        padding: '6px 8px',
                        justifyContent: 'center',
                        alignItems:'center',
                        gap:'10px', 
                        height:'24px',
                        backgroundColor:'#E6FFFB'
                }}>
              <img
                src={fileIcon} // Placeholder for file icon
                alt="file-icon"
                style={{ width: '32px', height: '20px' }}
              />
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <div style={{textAlign:'left'}}>{upload.file.name}</div>
              <div style={{ fontSize: '12px', color: '#aaa', textAlign:'left' }}>
                {(upload.file.size / 1024).toFixed(2)} KB
              </div>
              <div
                style={{
                  height: '8px',
                  width: '100%',
                  backgroundColor: '#FFFFFF',
                  borderRadius: '5px',
                  marginTop: '8px',
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    height: '100%',
                    width: `${upload.progress}%`,
                    backgroundColor: '#1890FF',
                    borderRadius: '5px',
                    transition: 'width 0.5s',
                  }}
                />
               
              </div>
              
            </div>
            <div
              style={{
                fontSize: '16px',
                color: '#f44336',
                cursor: 'pointer',
                width:'12px',
                height:'12px'
              }}
              onClick={() => handleDelete(upload.file)}
            >
              <img src={upload.progress==100?deleteIcon:closeIcon} alt="close icon"/>
              <div style={{color:'#000', fontSize:'8px'}}>{upload.progress}%</div>
            </div>
            
          </div>
        ))}
        
      </div>
    </div>
  );
};

export default ClickToUploadWithProgress;
