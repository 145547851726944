import React, { useState, useEffect } from 'react';
import './CustomModal.css'; // Optional for additional styling
import GenerateAI from "../../assets/generateAIModal.svg"
import { useStore } from './store/store';
import { useNavigate } from 'react-router-dom';
import { Input, InputNumber, Radio } from 'antd';
import { generateJd, updateVacancyRequests } from '../vacancies/Action/action';
import { useVacancyStore } from '../vacancies/store/store';

const CustomModal = ({ show, handleClose, title, subtitle }) => {
  // const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  const {progress,setProgress,setIsModalOpen,titleJob,titleIndustry, setJdData,mandatorySkills,optionalSkills} = useStore(state=>state);
  const {setJobExperience, jobExperience,showExperince,setShowExperince,jdEditModal,setJdEditModal} = useStore(state=>state);
  const {vacancyCreatorFlag,vacancyData}=useVacancyStore(state=>state)
  const [minExp,setMinExp]=useState(0);
  const [maxExp,setMaxExp]=useState(0);

  console.log(show,"SHOW",progress)

  useEffect(() => {
    let interval;
 // Reset progress when modal opens
      interval = setInterval(() => {
        if(progress>=100){
              clearInterval(interval);
              setIsModalOpen(false)
              //navigate('/generatedJd');
        }else{
          const newProgress=progress+Math.floor(Math.random()*10);
          if(newProgress<100){
          setProgress(newProgress);
          }
           else{
            setProgress(progress)
           }
        }
   
      }, 500); // Adjust timing for the progress update
  

    return () => clearInterval(interval);
  });


  const jobLevels = [
    { title: 'Entry Level', experience: '0-2 years' },
    { title: 'Junior Level', experience: '2-5 years' },
    { title: 'Mid Level', experience: '5-8 years' },
    { title: 'Senior Level', experience: '8-12 years' },
    { title: 'Manager Level', experience: '12-15 years' },
    { title: 'Principal Level', experience: '15+ years' },
    {title:"Custom Level", experience:'custom'}
  ];
const callApi=(exp)=>{
  const payload = {
    "role":titleJob,
    "must_have_skills":mandatorySkills,
    "good_to_have_skills": optionalSkills,
    "years_of_experience": jobExperience||exp,
    "degree_requirements": "",
    "industry":titleIndustry
  };


  generateJd(payload,(response,error)=>{
    setJdData(response?.response);
    setIsModalOpen(false);
    setJdEditModal(true);
    setJobExperience();
    setShowExperince("no")
  })
}
  
  
  const handleNext=()=>{
    setJobExperience();
    setShowExperince("no")
    setProgress(0)
    callApi()


  }

const handleSelect=()=>{
  const minexp=minExp??0
  const maxexp=maxExp?`-${maxExp} years`:'+ years'
const exp=(minexp+maxexp);
 
updateVacancyRequests({ jobId:vacancyData?.id, minExperience:minExp,maxExperience:maxExp,role:vacancyCreatorFlag?'Creator':"Responder"},(res,err) =>{
 })

 setShowExperince("no")
 setProgress(0);
 callApi(exp)

}

useState(()=>{
  if(showExperince==false && title!=="Analyzing Resume..."){
    setProgress(0)
    callApi()
  }
  else{
    setProgress(0)
    
  }
},[showExperince])

  return (
    <div className="modal">
    {showExperince=="yes"?
      <div className="modal-content">
      <span className="close" onClick={handleClose}>&times;</span>
      <h3 className='experince-modal-title'>Select experience</h3>
      <div className='experience-cards'>

     <div className='custom-level'>
       <div style={{width:'max-content',display:'flex',justifyContent:'flex-start',width:'100%'}}>Minimum Experince:</div>
       <InputNumber  type='number'  className='custom-level-input' min={0} placeholder='Enter min years of experince' onChange={(v)=>{setMinExp(v)}}/>
       <div style={{width:'max-content',display:'flex',justifyContent:'flex-start',width:'100%'}}>Maximum Experince:</div>
       <InputNumber  type='number' className='custom-level-input' min={0} placeholder='Enter max years of experince' onChange={(v)=>{setMaxExp(v)}}/>
     </div>

     
      {/* <Radio.Group onChange={(e)=>{
        if(e.target.value==jobExperience)
        setJobExperience()
      else 
      setJobExperience(e.target.value)}} value={jobExperience}>
      {jobLevels?.map(level=>{
        if(level?.title=='Custom Level'){
         return  <div className='level-card'>
        <Radio value={level.experience} checked={level.experience==jobExperience}/>
        <div>
          <h3>{level.title}</h3>
          {jobExperience=='custom'?<div className='custom-level'>
       
            <InputNumber  type='number'  min={0} placeholder='Enter min years of experince' onChange={(v)=>{setMinExp(v)}}/>
            
            <InputNumber  type='number'  min={0} placeholder='Enter max years of experince' onChange={(v)=>{setMaxExp(v)}}/>
          </div>:null
       
          }
     
        </div>


        </div>
        }else{
        return <div className='level-card'>
        <Radio value={level.experience} checked={level.experience==jobExperience}/>
        <div>
          <h3>{level.title}</h3>
          <p>({level.experience})</p>
        </div>


        </div>
        }
      })}
    </Radio.Group> */}
      </div>
  
          <button onClick={handleSelect} className="keyword-modal-done" style={{float:'right',background:'#1890FF',color:'white',border:'none'}}>
            Done
          </button>
          <button onClick={handleNext} className="keyword-modal-done" style={{float:'right',marginRight:'10px'}}>
            Skip
          </button>
      </div>

     : <div className="modal-content">
     
        <span className="close" onClick={handleClose}>&times;</span>
        <img src={GenerateAI} alt="Image"/>
        <div style={{padding:'20px'}}>
        <h3>{title}</h3>
        <p>{subtitle}</p>
        <div className="progress-bar-container">
          <div className="progress-bar" style={{ width: `${progress}%`}}></div>
          
        </div>
        </div>
        <div className="progress-bar-content">{progress}% completed</div>
      </div>
    }
     
    </div>
  );
};

export default CustomModal;