import { create } from "zustand";
export const useResumeLibraryStore=create(set=>({
    activeTab:'Requests',
    role:'',
    jobsResponse:[],
    jobFilters:null,
    layoutType:'grid',
    nFilters:{},
    vacancyData:null,
    previousUrl:'',
  




    setActiveTab:(val)=>set(state=>({activeTab:val})),
    setnFilters:(val)=>set({nFilters:val}),
    setVacancyData:(val)=>set({vacancyData:val}),
   setPreviousUrl:(val)=>set({previousUrl:val}),  
     setLayoutType:(val)=>set(state=>({layoutType:val}))
   
}))

