
import './App.css';

import MainRouter from './routes/router';

import { BrowserRouter } from 'react-router-dom'


function App() {
  return (
    <BrowserRouter>
   <div id="tooltip" class="tooltip-container"></div>
    <MainRouter/>
   
    </BrowserRouter>
  );
}

export default App;
