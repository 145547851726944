import React, { useState } from "react";
import Settings from "../../../assets/settings.svg"
import "../../hiring/createJD.css"
import BackArrow from "../../../assets/backArrow.svg"
import Header from "../../../landing/header.js";
import design from "../../../assets/backgrounddesign.svg"
import { Button, Modal } from "antd";
import CustomButton from "../../hiring/customButton.js";
import Results from "./customResults.js"
import CustomWeightage from "./customWeightage.js";
import { useLocation } from "react-router-dom";


const ResumeScreeningResults = () =>{
    const [weightageModal, setWeightageModal] = useState(false);
    const location = useLocation();
    const result = location.state || {}; // Access the passed data

   const handleWeightage = ()=>{
    console.log("IN FORM")
    setWeightageModal(true);
   }

    return(
    <div className="createJD-container" >
           
        <Header color={'#2337C6'} />
        <div className="job-description">
         <div class="flex-overlay-container" style={{left:'0'}}>
         <img src={design} class="image2" alt="Second Image"/>
        </div>
        {<div  className="createJD-child" style={{transform:'translateY(50px)', width:'740px'}} >
            <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'space-between'}}>
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', gap:'16px',left:'0'}}>
                    <div><img src={BackArrow} alt="back arrow"/></div>
                    <div>Back</div>
                </div>
                <div>
                    <CustomButton variant="outlined" text="Set Weightage" onClick={()=>{handleWeightage()}} img={Settings}/>
                </div>
            </div>
            <Results results={result}/>
            <CustomWeightage isModalOpen={weightageModal} handleOk={()=>{setWeightageModal(false)}} handleCancel={()=>{setWeightageModal(false)}}/>
         </div>}
 
        
       
        </div>
        
    </div>
    );
}

export default ResumeScreeningResults;