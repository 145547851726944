
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import Home from '../landing/home';
import Vacancies from '../pages/vacancies/vacancies';

import JobDescription from '../pages/hiring/jobDescription';
//import TextEditor from './pages/hiring/textEditor';
import CreateJD from '../pages/hiring/createJD';
import Dashboard from '../pages/dashboard/dashboard';
// import SideBar from '../sidebar/sidebar';
import SideBar from "../sidebar/sidebar"
import Header from '../header/header';
import '../App.css'
import RecruitmentOverview from '../pages/vacancies/vacancy_details/recruitmentOverview/recruitmentOverview';
import ActivitiesPage from '../pages/vacancies/vacancy_details/activities/activity';
import VacancyDetails from '../pages/vacancies/vacancy_details/vacancyDetails';
import VacancyWrapper from '../pages/vacancies/vacancyWrapper';
import Configuration from '../pages/settings/configuration';
import ResumeLibraryWrapper from '../pages/resumelibrary/resumeLibraryWrapper';
import CandidateInfo from "../pages/resumelibrary/candidate_profile/candidateProfile"
import ResumeScreening from '../pages/demoPages/resumeScreening/mainPage';
import ResumeScreeningResults from '../pages/demoPages/resumeScreening/resumeScreeningResults';
import ResumeLibrary from '../pages/resumelibrary/resumelibrary';

function MainRouter() {
  return (

<Routes>
  <Route path="/" element={<Home />} />
  <Route path="/createJd" element={<CreateJD />} />
  <Route path="generatedJd" element={<JobDescription />} />
  <Route path="/resumeScreening" element={<ResumeScreening />} />
  <Route path="/results" element={<ResumeScreeningResults />} />
  {/* Shared Layout Route for Dashboard, Vacancies, and Job Description */}
  <Route
    path="/"
    element={
      <div className="app">
        <div className="app-sidebar">
          <SideBar />
        </div>
        <div className="app-main">
          <Header />
          <Outlet  /> 
        </div>
      </div>
    }
  >
    <Route path="dashboard" element={<Dashboard />} />

      <Route path="vacancies" element={<VacancyWrapper/>} >
        <Route path="" element={<Vacancies tab={"Requests"}/>}/>
        <Route path="active" element={<Vacancies tab={"Active"}/>}/>
        <Route path="in-active" element={<Vacancies tab={"In-active"}/>}/>
        <Route path="analytics" element={<Vacancies tab={"Analytics & Reports"}/>}/>
        <Route  path=":id" element={<VacancyWrapper/>} >
            <Route path='' element={<VacancyDetails tab={"Job Overview"} />}/>
            <Route path='recruitment-overview' element={<VacancyDetails tab={"Recruitment Overview"} />}/>
            <Route path='activity' element={<VacancyDetails tab={"Activities"} />}/>
        </Route>
        <Route path="activities" element={<ActivitiesPage/>}/>
        <Route path='recruitment_overview' element={<RecruitmentOverview/>}/>
      </Route>
      <Route path="resume-library" element={<ResumeLibraryWrapper/>} >
        
        
        <Route path="activities" element={<ActivitiesPage/>}/>
        <Route path='candidate_info' element={<CandidateInfo/>}/>
      </Route>

    <Route  path='configuration' element={<Configuration/>}/>
 
  </Route>
</Routes>
  );
}

export default MainRouter;
