import React, { useState } from 'react';
import './JobLevel.css'; // Ensure your styles are updated accordingly
import { useStore } from './store/store';

const JobLevel = ({title,expYears}) => {
  const {setJobExperience, jobExperience} = useStore(state=>state);
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setJobExperience(expYears);
    setIsActive(!isActive);  // Toggle the active state
  };

  return (
    
      <div   
      className={`content ${jobExperience ==expYears? 'active' : ''}`}
      onClick={handleClick}>
        <div className="job-level-title">{title}</div>
        <div className="job-level-experience">{expYears}</div>
      </div>
     
   
  );
};

export default JobLevel;
