import React, { useEffect, useState } from 'react';
import rightArrow from "../../assets/rightArrow.svg";
import leftArrow from "../../assets/leftArrow.svg";
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';

const Pagination = ({ totalPages, currentPage, onPageChange, itemsPerPage, onItemsPerPageChange }) => {

    
    const [pageRange, setPageRange] = useState([1, Math.min(5, totalPages)]);

    // Helper function to update the page range when navigating
    const updatePageRange = (newPage) => {
        if (newPage > pageRange[1]) {
            setPageRange([pageRange[1] + 1, Math.min(pageRange[1] + 5, totalPages)]);
        } else if (newPage < pageRange[0]) {
            setPageRange([Math.max(1, pageRange[0] - 5), pageRange[0] - 1]);
        }
        onPageChange(newPage);
    };

    useEffect(()=>{
        setPageRange([1, Math.min(5, totalPages)]);
    },[totalPages])
    // Create an array of page numbers for the current range
    const pageNumbers = Array.from({ length: pageRange[1] - pageRange[0] + 1 }, (_, i) => pageRange[0] + i);

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{display:'flex',flexDirection:'row',gap: '8px',alignItems:'center'}}>
            {/* Left navigation button */}
            {totalPages?<>
            <button 
                onClick={() => updatePageRange(currentPage - 1)} 
                disabled={currentPage === 1}
                style={{...buttonStyle,
                    cursor: totalPages>1 && currentPage!==1?'pointer':'not-allowed'
                }}
            >
                {currentPage !== 1 ? 
                    <img src={rightArrow} style={{ transform: 'rotate(180deg)' }} alt="leftArrow" /> : 
                    <img src={leftArrow} alt="leftArrow" />}
            </button>

            {
                ( currentPage>5)?
                <>
                <button
            
                    onClick={() => updatePageRange(1)}
                    style={{
                        ...buttonStyle,
                        border: currentPage === 1 ? '2px solid #1890FF' : '1px solid #D0D0D0',
                        color: currentPage === 1 ? '#1890FF' : '#4D4D4D'
                    }}
                >
                  1
                </button>
                <button
                className='backward-page'
                    style={{
                        ...buttonStyle,
                        border: '1px solid #D0D0D0',
                        color:  '#4D4D4D'
                    }}
                    onClick={()=>{
                        updatePageRange(currentPage-5)
                    }}
                >
                  <span className='more'>...</span>
                  <span className='backward-left'><DoubleLeftOutlined style={{color:'#1890FF'}}/></span>
                </button>
             
                </>
                :null

            }
            {/* Page numbers */}
            {pageNumbers.map((page) => (
                <button
                    key={page}
                    onClick={() => updatePageRange(page)}
                    style={{
                        ...buttonStyle,
                        border: currentPage === page ? '2px solid #1890FF' : '1px solid #D0D0D0',
                        color: currentPage === page ? '#1890FF' : '#4D4D4D'
                    }}
                >
                    {page}
                </button>
            ))}
            {
                (totalPages> 5 && pageNumbers.length>=5  )?
                <>
                <button
                className='forward-page'
                onClick={() => updatePageRange(currentPage+5)}
                    style={{
                        ...buttonStyle,
                        border: '1px solid #D0D0D0',
                        color:  '#4D4D4D'
                    }}
                >
                    <span className='more'>...</span>
                  <span className='forward-right'><DoubleRightOutlined style={{color:'#1890FF'}}/></span>
                  </button>
                <button
                    key={totalPages}
                    onClick={() => updatePageRange(totalPages)}
                    style={{
                        ...buttonStyle,
                        border: currentPage === totalPages ? '2px solid #1890FF' : '1px solid #D0D0D0',
                        color: currentPage === totalPages ? '#1890FF' : '#4D4D4D'
                    }}
                >
                    {totalPages}
                </button>
                </>
                :null

            }

            {/* Right navigation button */}
            <button 
                onClick={() => updatePageRange(currentPage + 1)} 
                disabled={currentPage === totalPages}
                style={{...buttonStyle,
                    cursor: totalPages>1 && currentPage!==1?'pointer':'not-allowed'
                }}
            >
                {currentPage !== totalPages ? 
                    <img src={rightArrow} alt="rightArrow" /> : 
                    <img src={leftArrow} style={{ transform: 'rotate(180deg)' }} alt="leftArrow" />}
            </button>
         
            </>
            :
            <>
                <div style={animatedButton} > <img src={leftArrow} alt="leftArrow" /></div>
                <div style={animatedButton} ></div>
                <div style={animatedButton} ></div>
                <div style={animatedButton} ></div>
                <div style={animatedButton} ></div>
                <div style={animatedButton} ></div>
                <div style={animatedButton}  ><img src={leftArrow} alt="rightArrow"  style={{ transform: 'rotate(180deg)' }}/></div>
            </>
            
            }
            </div>

            {/* Items per page dropdown */}
            <select
                value={itemsPerPage}
                onChange={(e) => onItemsPerPageChange(Number(e.target.value))}
                style={selectStyle}
            >
                <option value={10}>10 / page</option>
                <option value={20}>20 / page</option>
                <option value={30}>30 / page</option>
                <option value={40}>40 / page</option>
            </select>
        </div>
    );
};
const buttonStyle = {
    width: '34px',
    height: '34px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '6px',
    backgroundColor: '#ffffff',
    cursor: 'pointer',
    outline: 'none',
    border: '1px solid #D0D0D0',
};

const selectStyle = {
    padding: '6px 12px',
    backgroundColor: '#f5f5f5',
    fontSize: '14px',
    cursor: 'pointer',
    borderRadius: 'var(--Radius-XS, 4px)',
    border: '1px solid var(--boarders-lines-neutral-subtle, #D0D0D0)',
    background: 'var(--Backgrounds-white, #FFF)',
    height: '34px'
};
const optionsStyle = {
    color: 'var(--Texts-body, #4D4D4D)',


fontFamily: 'Figtree',
fontSize: '14px',
fontStyle: 'normal',
fontWeight: '400',
lineHeight: 'normal',
}

const animatedButton={
    width: '34px',
    height: '34px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '6px',
    backgroundColor: '#ffffff !important',
    outline: 'none',
    border: '1px solid #D0D0D0',
}
export default Pagination;
