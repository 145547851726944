
import './homeStyles.css';

function Footer() {

  return (
    <div className="landing_footer">
 
        <span className='landing_copy-right'>© 2024 whiteink.ai | All rights reserved.</span>
  
        <span className='landing_contact-info'>For more information, reach out to us at <b>info@brigosha.com</b></span>
 

    </div>
  );
}

export default Footer;