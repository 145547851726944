import { useEffect, useState,useRef } from "react"
import { renderToString } from 'react-dom/server';
import ReactDOM from 'react-dom';
import './styles.css'
import Divider from "../../../../components/divider/Divider"
import StatusBar from "../../jobs/StatusBar"
import remainder from '../../../../assets/icons/remainder.svg'
import { CheckOutlined, CloseOutlined, CopyOutlined, DownloadOutlined, DownOutlined, EditOutlined, ExclamationCircleFilled, InfoCircleFilled, InfoCircleOutlined, LoadingOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { Button, Dropdown, Input, message, Modal, Spin } from "antd"
import JobIndustrySelector from "../../../hiring/jobIndustrySelector"
import CustomButton from "../../../hiring/customButton"
import Generate from "../../../../assets/generateIcon.svg"
import CustomModal from "../../../hiring/customModal"
import { useStore } from "../../../hiring/store/store"
import parse, { htmlToDOM } from 'html-react-parser';
import EditorToolbar, { modules, formats } from "../../../../components/EditorToolbar/EditorToolbar";
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css';
import jobInfo from '../../../../assets/icons/job-basic.svg'
import jobRequirement from '../../../../assets/icons/job-requirement.svg'
import jobCompensation from '../../../../assets/icons/job-compensation.svg'
import addJd from '../../../../assets/icons/add-jd.svg'
import addKeywords from '../../../../assets/icons/add-keyword.svg'
import jdIcon from '../../../../assets/icons/jd-icon.svg'
import jobKeywords from '../../../../assets/icons/job-keywords.svg'
import { generateJd, getJobOverview, updateVacancyRequests } from "../../Action/action"
import { useParams } from 'react-router-dom';
import { useVacancyStore } from "../../store/store"
import JobOverviewEditModal from "./editModal"
import { jsPDF } from 'jspdf';


const JobOverview=({data})=>{
 
  
    const [show, setShow] = useState(false);
    const [loadMore,setLoadMore]=useState(false);
    const [mandatoryKeywordModal,setMandatoryKeywordModal]=useState(false);
    const [optionalKeywordModal,setOptionalKeywordModal]=useState(false);
    const [ mandatoryKeywords,setMandatoryKeywords]=useState([]);
    const [loading,setLoading]=useState(false);
    const [optionalKeywords,setOptionalKeywords]=useState([]);
    const [newMandatoryKeyword,setNewMandatoryKeyword]=useState();
    const [newOptionalKeyword,setNewOptionalKeyword]=useState();
    const [jobEditModal,setJobEditModal]=useState(false);
    const [jdModal,setJdModal]=useState(false);
    const [jdLoading,setjdLoding]=useState(false);
    const { id } = useParams();
    const [currentJd,setCurrentJd]=useState();
    const [editJd,setEditJd]=useState();
    const [keywordsLoding,setKeywordsLoding]=useState(false);
    const {titleJob, titleIndustry,isModalOpen,setIsModalOpen,jdData,
      setJdData,jobExperience,progress,setProgress,  setJobExperience,
       showExperince,setShowExperince,setTitleJob,setMandatorySkills,setOptionalSkills,jdEditModal,setJdEditModal} = useStore(state=>state);

 const { setVacancyData,vacancyData,vacancyCreatorFlag}=useVacancyStore(state=>state);
 const [isOverflow, setIsOverflow] = useState(false);
 const generateJdRef = useRef(null);
 const { confirm } = Modal;
 const jdTemplateRef = useRef(null);
  
    const handleCopy=()=>{
        const pTag = document.createElement('p');
         pTag.innerHTML =currentJd;
        navigator.clipboard.writeText(pTag.innerText)
        .then(() => {
          // alert('Content copied to clipboard!');
     
          message.success("Text copied to clipboard.")

        })
        .catch(err => {
          // console.error('Failed to copy: ', err);
        });
        
    }


   const JdTemplate = ({ jobTitle, jobCode,description}) => {
        return(
          <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px',fontSize:'8px' ,width:'400px',lineHeight:'1.3'}}

          >
            <h1 style={{  color: '#333', fontSize:'14px',lineHeight:'1px'}}>{jobTitle}</h1>
            {jobCode && (
              <p style={{  color: '#555', fontStyle: 'italic',fontSize:'10px',lineHeight:'1px',marginTop:20 }}>
               {jobCode}
              </p>
            )}
      
            <section style={{ marginBottom: '20px' }}>
              <h2 style={{ color: '#444',fontSize:'12px' }}>Job Description</h2>
              <p style={{ color: '#666',fontSize:'10px', }}>{description}</p>
              
            </section>
      
      
          </div>)
        
      };
  
    const handleGeneratePdf = () => {
   
      const payload={
        jobTitle: vacancyData?.jobTitle||'--',
        jobCode: vacancyData?.jobCode||'-',
        description:parse(currentJd),
      
      }
       const divTag=document.createElement('div');
       ReactDOM.render(<JdTemplate {...payload} />, divTag);

       function generateUrlFriendlyLink(jobTitle) {
        // Remove characters that are not alphanumeric or spaces
        const sanitizedTitle = jobTitle.replace(/[^a-zA-Z0-9\s]/g, '');
        
        // Replace spaces with hyphens and convert to lowercase
        const urlFriendlyLink = sanitizedTitle.trim().replace(/\s+/g, '-').toLowerCase();
      
        return urlFriendlyLink;
      }

      const fileName=generateUrlFriendlyLink(vacancyData?.jobTitle)+"_"+vacancyData?.jobCode
      const pdf = new jsPDF({orientation:'portrait',unit:'px'});
      pdf.html(divTag, {
        callback: (doc) => {
          doc.save(`${fileName}_jd.pdf`);
        }
      });
    //   const options = {
    // margin: [10, 10, 10, 10],
    //     filename: 'job-description.pdf',
    //     image: { type: 'jpeg', quality: 1 },
    //     html2canvas: { scale: 10 }, // Adjust the resolution
    //     jsPDF: { unit: 'px', format: 'a4', orientation: 'portrait' },
   
    //   };
    
    //   html2pdf()
    //   .set(options)
    //   .from(jdTemplateRef.current)
    //   .toPdf()
    //   .save();
    
  
  
  };

    

 const showConfirm = () => {
   confirm({
     title: 'Exit Without Saving?',
     icon: <ExclamationCircleFilled />,
     content: 'You have unsaved changes. Exiting now will discard them. Do you still want to proceed?',
     onOk() {
     setJdModal(false);
     setJdEditModal(false);
      setEditJd(currentJd);
      setShow(false);
     },
     onCancel() {
       
     },
   });
 };
    useEffect(()=>{
    
    setIsModalOpen(false);
    const params={
      jobId:id,
      role:vacancyCreatorFlag?'Creator':"Responder"
    }
    fetchJobData(params);


    },[data])

    

    useEffect(()=>{
    
     

     if(jdEditModal && !jdModal ){
      setJdModal(true);
      setTimeout(()=>{
        setShow(true);
    }, 100);

     }

     if(jdEditModal){
      setEditJd(jdData);
     }
  
    
    },[jdEditModal,jdData])

  

    const fetchJobData=(params)=>{
      setLoading(true);
      getJobOverview(params,(response,error)=>{
        if (error) {
          console.error("Error posting vacancy:", error);
        } else {
      
          setVacancyData(response?.jobs?.[0]);
         // setJdData(response?.jobs?.[0]?.jobDescription);
          setTitleJob(response?.jobs?.[0]?.jobTitle);
          setMandatoryKeywords(response?.jobs?.[0]?.mandatoryKeywords??[])
          setOptionalKeywords(response?.jobs?.[0]?.optionalKeywords??[]);
          setMandatorySkills(response?.jobs?.[0]?.mandatoryKeywords?.join(','))
          setOptionalSkills(response?.jobs?.[0]?.optionalKeywords?.join(','));
          setCurrentJd(response?.jobs?.[0]?.jobDescription);
          setEditJd(response?.jobs?.[0]?.jobDescription);
        }
        setLoading(false)
      })
    }


    const updateStatus=(status)=>{
     
      let params={status, jobId:id,   role:vacancyCreatorFlag?'Creator':"Responder"};
      updateVacancyRequests(params,()=>{
        const params={
          jobId:id,
        role:vacancyCreatorFlag?'Creator':"Responder"
        }
        fetchJobData(params);
  
      })
    }

   const updateMandatoryKeywords=()=>{
    //api call
    if(mandatoryKeywords.length==0){
      message.warning("Please input mandatory skills")
      return;
    }
    setKeywordsLoding(true);
    let params={mandatoryKeywords:mandatoryKeywords?.join(','), jobId:id,   role:vacancyCreatorFlag?'Creator':"Responder"};
    updateVacancyRequests(params,()=>{
      const params={
        jobId:id,
           role:vacancyCreatorFlag?'Creator':"Responder"
      }
      fetchJobData(params);
      setKeywordsLoding(false);
      setMandatoryKeywordModal(false);

    })
   }
   const updateOptionalKeywords=()=>{
    //api call
    if(optionalKeywords.length==0){
      message.warning("Please input optional skills")
      return;
    }
    setKeywordsLoding(true);
    let params={optionalKeywords:optionalKeywords?.join(','), jobId:id,   role:vacancyCreatorFlag?'Creator':"Responder"};
    updateVacancyRequests(params,()=>{
      const params={
        jobId:id,
           role:vacancyCreatorFlag?'Creator':"Responder"
      }
      fetchJobData(params);
      setKeywordsLoding(false);
      setOptionalKeywordModal(false);

    })
   }

   const postData=()=>{
    //api call
    if(titleJob===''){
        message.warning("Please input job title ")
        return;
    }
    if(titleIndustry===''){
      message.warning("Please select relevant industry")
      return;
  }
    if(!vacancyData?.minExperience && !vacancyData?.maxExperience)
      setShowExperince("yes");
    else{
      const minexp=vacancyData?.minExperience??0
      const maxexp=vacancyData?.maxExperience?`-${vacancyData?.maxExperience} years`:'+ years'
      setJobExperience(minexp+maxexp);
      setShowExperince("no");
    }

    setIsModalOpen(true);
  
    // setIsLoading(true);

   }

   const updateJd=()=>{
   
   
    setjdLoding(true);

    const cleanedValue = editJd?.replace(/<p>\s*(.*?)\s*<\/p>/g, (match, innerContent) => `<p>${innerContent?.trim()}</p>`)
    .replace(/^(<p><br\s*\/?\s*><\/p>\s*)+/, "") // Remove <p><br/></p> at the beginning
  .replace(/(\s*<p><br\s*\/?\s*><\/p>)+$/, "");
    const isBlank = /<p>\s*<\/p>/g.test(cleanedValue);
    //condition check

    let params={ jobDescription:isBlank?"":cleanedValue , jobId:id,   role:vacancyCreatorFlag?'Creator':"Responder"};
    updateVacancyRequests(params,()=>{
      const params={
        jobId:id,
       role:vacancyCreatorFlag?'Creator':"Responder"
      }
      fetchJobData(params);
      setjdLoding(false);
      setJdEditModal(false);
      setJdModal(false);
      setShow(false);

    })
   
    //api calll
   }
  
   const generateJdModal=()=>{
// fetch jd api call with title and industry and experinece
   if(!vacancyData?.minExperience && !vacancyData?.maxExperience){
      setShowExperince("yes");
   }
   else{
    const minexp=vacancyData?.minExperience??0
    const maxexp=vacancyData?.maxExperience?`-${vacancyData?.maxExperience} years`:'+ years'
    setJobExperience(minexp+maxexp);
    setShowExperince("no");
  }
  
setIsModalOpen(true);
setProgress(0);
// const payload = {
//   "role":vacancyData?.jobTitle,
//   "must_have_skills":"",
//   "good_to_have_skills": "",
//   "years_of_experience": vacancyData?.minExperience+"-"+vacancyData?.maxExperience+" years",
//   "degree_requirements": "",
//   "industry":vacancyData?.industyExperince,
//   "jobDescription":jdData
// };

// generateJd(payload,(response,error)=>{
//   console.log("ai generated jd ",response
//   )
//   setJdData(response?.response);
//   setProgress(100)
//   setIsModalOpen(true);
// })



   }
    const addMandatoryKeyword=()=>{
        if(newMandatoryKeyword?.trim()){
        setMandatoryKeywords( [newMandatoryKeyword.trim(),...mandatoryKeywords])
        setNewMandatoryKeyword();
        }else{
          setNewMandatoryKeyword();
        }
    }
    const addOptionalKeyword=()=>{
      if(newOptionalKeyword?.trim()){
      setOptionalKeywords( [newOptionalKeyword.trim(),...optionalKeywords])
      setNewOptionalKeyword();
      }else{
        setNewOptionalKeyword();
      }
  }
const removeMandatoryKeyword=(keyword)=>{
    setMandatoryKeywords( mandatoryKeywords?.filter(v => v !== keyword))
}
const removeOptionalKeyword=(keyword)=>{
  setOptionalKeywords( optionalKeywords?.filter(v => v !== keyword))
}

const handleCloseModal = () =>{
    setIsModalOpen(false)
}
const handleChange=(value)=>{
  
      setEditJd(value)
    
}

function daysDifference(givenDate) {
  const currentDate = new Date(); // Current date
  const date = new Date(givenDate?.replace(/-/g, '/')); // Parse given date correctly
  
  // if (isNaN(date)) {
  //   console.log('Invalid date format. Use YYYY-MM-DD.',givenDate);
    
  // }

  // Calculate the difference in milliseconds
  const differenceInMillis = date - currentDate;
  
  // Convert milliseconds to days
  const differenceInDays = Math.ceil(differenceInMillis / (1000 * 60 * 60 * 24));
  console.log("date in days",differenceInDays,currentDate)
  return differenceInDays<0?0:differenceInDays||'-';
 

}



 function timeSince(date) {
  const now = new Date();


  const givenDate = new Date(date);
  if(isNaN(new Date(date))){
    return NaN
     }
  const diffInSeconds = Math.floor((now - givenDate) / 1000);

  if (diffInSeconds < 60) {
    return `${diffInSeconds} second${diffInSeconds === 1 ? '' : 's'}`;
  }

  const diffInMinutes = Math.floor(diffInSeconds / 60);
  if (diffInMinutes < 60) {
    return `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'}`;
  }

  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) {
    return `${diffInHours} hour${diffInHours === 1 ? '' : 's'}`;
  }

  const diffInDays = Math.floor(diffInHours / 24);


  if (diffInDays < 30) {
    return `${diffInDays} day${diffInDays === 1 ? '' : 's'} ago`;
  }

  const diffInMonths = Math.floor(diffInDays / 30);
  if (diffInMonths < 12) {
    return `${diffInMonths} month${diffInMonths === 1 ? '' : 's'} ago`;
  }

  const diffInYears = Math.floor(diffInMonths / 12);
  return `${diffInYears} year${diffInYears === 1 ? '' : 's'} ago`;

  
}

    const statusBtnColor=(status)=>{
     
        switch(status){
            case "Closed":return "#F5222D";
            case "On-hold":return "#FA8C16";
            case "Active":return "#52C41A"
            default: return "#E9E9E9";

        }
    }
 
    const statusList=[
        {
            key:'Active',
            label:<>Active &nbsp; {vacancyData?.status=='Active'?<CheckOutlined style={{color:'#1890FF'}}/>:null}</>
        },
        {
            key:'On-hold',
          
            label: <>On-hold  &nbsp; {vacancyData?.status=='On-hold'?<CheckOutlined style={{color:'#1890FF'}}/>:null}</>
              
          },
        {
            key:'Closed',
           
            label: <>Close  &nbsp; {vacancyData?.status=='Closed'?<CheckOutlined style={{color:'#1890FF'}}/>:null}</>
        }
      
        ]

 


        useEffect(() => {
            // Check if height exceeds 700px
            if (generateJdRef.current && generateJdRef.current.scrollHeight > 700) {
              setIsOverflow(true);
            }
          });
    return(
        <>
        <div className="job-overview">
         <div className="section-1">
     
       <div className="job-status">
       <div className="jobcard-header">
        <div>
        <h3 className="jobcard-title">Status</h3>
        <span className="jobcard-field">{vacancyData?.status=='Active'?'The job is open, and hiring efforts are in motion.':`This position is ${vacancyData?.status?.toLowerCase()||'-'} while we reassess hiring needs for the role.`}</span>
        </div>
       
        <Dropdown
        menu={{
            items:statusList,
            defaultSelectedKeys: [vacancyData?.status],
        onClick:({key})=>{
    
         updateStatus(key);
        }
        }}
        trigger={['click']}
        placement="bottomRight"
        style={{padding:'0'}}
   
    
      >
            <button style={{background:statusBtnColor(vacancyData?.status)}} className="joboverview-status"><p style={{width:'max-content'}}>{vacancyData?.status?.toUpperCase()??'-'}</p> <DownOutlined/></button>
     
        </Dropdown>

       
        </div>
        {/* <Divider/>
        <div>
            <h3>Stages</h3>
            <p></p>
            <div className="jobcard-status">
            <StatusBar percent={vacancyData?.percentage??0} bars={vacancyData?.template?.stage?.map(e=>e?.stageKey)} stage={vacancyData?.stage}/>
          

        </div>
        </div> */}
        <div className="job-status-remainder"> 
        <img src={ remainder }></img>
        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',gap:'5px'}} >
        <p style={{margin:0}} className="job-status-days">{daysDifference(vacancyData?.anticipatedJoinDate)} days remaining to secure the ideal candidate.</p>
        <p style={{margin:0}} className="job-status-info"><InfoCircleOutlined style={{color:'#7A7A7A'}}/>  {timeSince(vacancyData?.createdAt)||0} since activated.</p>
        </div>
    

        </div>
      </div>
      
      <div className="job-info">
      <div className="job-info-header-1">
                <div className="job-info-title">
                <img src={jobInfo}></img>
                <div>Basic Job Information</div>
                </div>
                <div className="job-info-edit" onClick={()=>{
                  setJobEditModal(true)
                }}>
                  <EditOutlined style={{ color: "#1890FF"}} />
                   <div> Edit</div>
                </div>
                  
        </div>
        <div className="job-info-data">
          <div className="data-field">
           <div className="data-name">Job title</div>
           <div className="data-value">{vacancyData?.jobTitle??'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Job Code</div>
           <div className="data-value">{vacancyData?.jobCode??'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Job Type</div>
           <div className="data-value">{vacancyData?.jobType??'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Department</div>
           <div className="data-value">{vacancyData?.department??'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Team</div>
           <div className="data-value">{vacancyData?.team??'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Job location</div>
           <div className="data-value">{vacancyData?.jobLocation??'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Work arrangements</div>
           <div className="data-value">{vacancyData?.workArrangements??'-'}</div>
          </div>
        </div>
        <Divider/>
        <div className="job-info-header-2">
                <div className="job-info-title">
                <img src={jobRequirement}></img>
                <div>Job Requirements</div>
                </div>
               
                  
        </div>
        <div className="job-info-data">
          <div className="data-field">
           <div className="data-name">Qualifications</div>
           <div className="data-value">{vacancyData?.qualification??'-'}</div>
          </div>
         
          <div className="data-field">
           <div className="data-name"> Specializations</div>
           <div className="data-value">{vacancyData?.specializations??'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Certifications</div>
           <div className="data-value">{vacancyData?.certifications??'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Industry-specific experience</div>
           <div className="data-value">{vacancyData?.industryExpertise ??'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Years of experience</div>

           {vacancyData?.minExperience || vacancyData?.maxExperience?
            <div className="data-value"> {vacancyData?.minExperience??0}{vacancyData?.maxExperience?` - ${vacancyData?.maxExperience}`:`+`}  years</div>:
            <div className="data-value">-</div>}

        
          </div>
          <div className="data-field">
           <div className="data-name">Preferred notice period (In days)</div>
           <div className="data-value">{vacancyData?.noticePeriod??'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Travel involvement</div>
           <div className="data-value">{vacancyData?.travelInvolvement ??'-'}</div>
          </div>
          </div>
        <Divider/>
        <div className="job-info-header-3">
        
                <div className="job-info-title">
                <img src={jobCompensation}></img>
                <div>Compensation</div>
                </div>
               
                  
        </div>

        <div className="job-info-data">
      
        <div className="data-field">
           <div className="data-name">  Currency</div>
           <div className="data-value">{vacancyData?.currency??'-'}</div>
          </div>
         
          <div className="data-field">
           <div className="data-name"> Compensation structure</div>
           <div className="data-value">{vacancyData?.compensationStructure??'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Range</div>
           {vacancyData?.minCompensation || vacancyData?.maxCompensation?
            <div className="data-value"> {vacancyData?.minCompensation??'-'}-{vacancyData?.maxCompensation??'-'}</div>:
            <div className="data-value">-</div>}
          </div>
          </div>
      </div>
          
         </div>
         <div className="section-2">
            <div className="job-description">
            <div className="jd-header">
                <div className="jd-title">
                <img src={jdIcon}></img>
                <div>Job Description</div>
                </div>

              
                {currentJd?
                  <div className="jd-actions">

               
                <div className="jd-edit" style={{cursor:currentJd?'pointer':'not-allowed'}} onClick={()=>{
                  if(currentJd)
                    handleCopy()
                }}>
                  <CopyOutlined style={{ color: "#FA8C16",cursor:currentJd?'pointer':'not-allowed'}} />
                   <div style={{color:"#FA8C16",cursor:currentJd?'pointer':'not-allowed'}}> Copy text</div>
                </div>
                <div className="jd-edit" style={{cursor:currentJd?'pointer':'not-allowed'}} onClick={()=>{
                   handleGeneratePdf();
                }}>
                  <DownloadOutlined style={{ color: "#52C41A",cursor:currentJd?'pointer':'not-allowed'}} />
                   <div style={{color:'#52C41A',cursor:currentJd?'pointer':'not-allowed'}}> Download</div>
                </div>
                <div className="jd-edit" style={{cursor:'pointer'}} onClick={()=>{
                        setTimeout(()=>{
                            setShow(true);
                        }, 100);
                       setJdModal(true)
                }}>
                  <EditOutlined style={{ color: "#1890FF"}} />
                   <div> Edit</div>
                </div>
                </div>
                :  null
                
                }
              
                </div>
              {loading?
              <div style={{display:'flex',flexDirection:'column',gap:'10px',width:'100%'}}>
              <h3 className="animated-bg animated-bg-text" style={{width:'50%',height:'18px'}}></h3>
              <p className="animated-bg animated-bg-text" style={{width:'90%',height:'15px'}}></p>
              <p className="animated-bg animated-bg-text" style={{width:'80%',height:'15px'}}></p>
              <p className="animated-bg animated-bg-text" style={{width:'50%',height:'15px'}}></p>
              <p className="animated-bg animated-bg-text" style={{width:'40%',height:'15px'}}></p>
              <p className="animated-bg animated-bg-text" style={{width:'50%',height:'15px'}}></p>
              <p className="animated-bg animated-bg-text" style={{width:'30%',height:'15px'}}></p>
              <h3 className="animated-bg animated-bg-text" style={{width:'70%',height:'18px',marginTop:'10px'}}></h3>
              <p className="animated-bg animated-bg-text" style={{width:'60%',height:'15px'}}></p>
              <p className="animated-bg animated-bg-text" style={{width:'30%',height:'15px'}}></p>
              <p className="animated-bg animated-bg-text" style={{width:'60%',height:'15px'}}></p>
              <p className="animated-bg animated-bg-text" style={{width:'90%',height:'15px'}}></p>
              <p className="animated-bg animated-bg-text" style={{width:'50%',height:'15px'}}></p>
              <p className="animated-bg animated-bg-text" style={{width:'20%',height:'15px'}}></p>
              <h3 className="animated-bg animated-bg-text" style={{width:'40%',height:'18px',marginTop:'10px'}}></h3>
              <ul>
              <li className="animated-bg animated-bg-text" style={{width:'60%',height:'15px',marginTop:'0px'}}></li>
              <li className="animated-bg animated-bg-text" style={{width:'80%',height:'15px',marginTop:'10px'}}></li>
              <li className="animated-bg animated-bg-text" style={{width:'50%',height:'15px',marginTop:'10px'}}></li>
              <li className="animated-bg animated-bg-text" style={{width:'90%',height:'15px',marginTop:'10px'}}></li>
              <li className="animated-bg animated-bg-text" style={{width:'50%',height:'15px',marginTop:'10px'}}></li>
              </ul>

              </div>
                :
              
                currentJd?<>
              
              
                <div
                className="generateJd"
                ref={generateJdRef}
                style={{
                height: loadMore ? "800px" : "600px",
                width:'100%',
                overflow:loadMore?'scroll': "hidden",
                position:'relative'
                }}
                >
                <div ref={jdTemplateRef}>
                {parse(currentJd)}
                </div>
               
                
                {isOverflow && !loadMore && (
                <div className="load-more">
                    <div onClick={() => setLoadMore(true)}>
                    <PlusCircleOutlined style={{ color: "#1890FF" }} /> Load More
                    </div>
                </div>
                )}
                </div>
                </>
                
              :
              <div className="add-jd" style={{padding:0}}>
                <div className="generateJd">
                <img src={addJd}></img>
                    <h3>Add Job Description</h3>
                    <p>AI-powered job descriptions at your fingertips—just click and go!</p>

                    <div style={{display:'flex', flexDirection:'row',gap:'1rem', alignItems:'center',justifyContent:'center',flexWrap:'wrap',}}>
                 <JobIndustrySelector/>
                 <CustomButton text="Generate" img={Generate} onClick={postData} variant="contained"/>
                </div>
                </div>
                
             <div className="skip-ai">
               <p>Would you like to manually input the job description?</p>
               <span onClick={()=>{
                 
                   setTimeout(()=>{
                    setShow(true);
                        },100);
                       setJdModal(true)
               }}>Skip AI</span>
                </div>
                </div>

            
                


              }
              
               
               



            </div>
            <div className="job-keywords">

                <div className="keywords-header">
                <div className="keywords-title">
                <img src={jobKeywords}></img>
                <div>keywords</div>
                </div>
            
                  
                </div>

                <div className="keywords-header">
                <div  className="keywords-title" style={{marginLeft:8,width:"100%",display:"flex",flexDirection:"row",color:'#1F1F1F',fontSize:'16px',fontWeight:600}}><div style={{height:20,width:4,backgroundColor:"#2337C6",marginRight:4}}></div>Mandatory Skills</div>
               {mandatoryKeywords.length!=0?
                <div className="keywords-edit" onClick={()=>{
                    setMandatoryKeywordModal(true)
                }}>
                  <EditOutlined style={{ color: "#1890FF"}} />
                   <div> Edit</div>
                </div>:null}
                </div>
               { mandatoryKeywords?.length>0?
                <div className="keywords-list">
                {mandatoryKeywords?.map(keyword=>{
                    return <div>{keyword}</div>
                  })}
                </div>:
                <div className="keywords-nodata" >
                    <img src={addKeywords}></img>
                    <h3>No keywords yet!</h3>
                    <p>Add specific keywords to improve search accuracy</p>
                    <button  onClick={()=>{
                    setMandatoryKeywordModal(true)
                }}><PlusCircleOutlined/> Add keywords</button>
                </div>
               }

               <div className="keywords-header">
                <div  className="keywords-title" style={{marginLeft:8,width:"100%",display:"flex",flexDirection:"row",color:'#1F1F1F',fontSize:'16px',fontWeight:600}}><div style={{height:20,width:4,backgroundColor:"#2337C6",marginRight:4}}></div>Optional Skills</div>
                {optionalKeywords.length!=0?
                <div className="keywords-edit" onClick={()=>{
                    setOptionalKeywordModal(true)
                }}>
                  <EditOutlined style={{ color: "#1890FF"}} />
                   <div> Edit</div>
                </div>:null}
                </div>
              
                { optionalKeywords?.length>0?
                <div className="keywords-list">
                  { optionalKeywords?.map(keyword=>{
                    return <div>{keyword}</div>
                  })}
                </div>:
                <div className="keywords-nodata" >
                    <img src={addKeywords}></img>
                    <h3>No keywords yet!</h3>
                    <p>Add specific keywords to improve search accuracy</p>
                    <button  onClick={()=>{
                    setOptionalKeywordModal(true)
                }}><PlusCircleOutlined/> Add keywords</button>
                </div>
               }
            </div>
         </div>
       
        </div>

        <Modal
        title={<p className="keyword-modal-title">Mandatory Skills</p>}
        footer={
        !keywordsLoding?  <button onClick={updateMandatoryKeywords} className="keyword-modal-done">
            Done 
          </button>:
          <>
            <button className="keyword-modal-done" style={{color:'rgb(255,255,255,0.7',background:'#4D4D4D'}}> Done  <Spin indicator={<LoadingOutlined spin />} size="small" /></button>
          </>

        }
       
        open={mandatoryKeywordModal}
        onCancel={() => setMandatoryKeywordModal(false)}
      >
        <div className="keyword-input-wrapper">
            <div className="keyword-input">
            <Input placeholder="Enter keyword"  className="keyword-input-field" value={newMandatoryKeyword} onChange={(e)=>{setNewMandatoryKeyword(e.target.value)}}/>
            <button  onClick={addMandatoryKeyword}><PlusCircleOutlined/> Add</button>
            </div>
            { mandatoryKeywords?.length>0?
            <div className="keywords-list">
            {mandatoryKeywords?.map(keyword=>{
                    return <div>{keyword} <CloseOutlined onClick={()=>removeMandatoryKeyword(keyword)}/></div>
                  })}
            </div>
           :
            <div className="keywords-nodata" style={{width:'100%',height:380,padding:'0px'}}>
                    <img src={addKeywords}></img>
                    <h3>No keywords yet!</h3>
                    <p>Add specific keywords to improve search accuracy</p>
              
                </div>
            }
        </div>
      

    
      </Modal>
      <Modal
        title={<p className="keyword-modal-title">Optional Skills</p>}
        footer={
        !keywordsLoding?  <button onClick={updateOptionalKeywords} className="keyword-modal-done">
            Done 
          </button>:
          <>
            <button className="keyword-modal-done" style={{color:'rgb(255,255,255,0.7',background:'#4D4D4D'}}> Done  <Spin indicator={<LoadingOutlined spin />} size="small" /></button>
          </>

        }
       
        open={optionalKeywordModal}
        onCancel={() => setOptionalKeywordModal(false)}
      >
        <div className="keyword-input-wrapper">
            <div className="keyword-input">
            <Input placeholder="Enter keyword"  className="keyword-input-field" value={newOptionalKeyword} onChange={(e)=>{setNewOptionalKeyword(e.target.value)}}/>
            <button  onClick={addOptionalKeyword}><PlusCircleOutlined/> Add</button>
            </div>
            { optionalKeywords?.length>0?
            <div className="keywords-list">
            {optionalKeywords?.map(keyword=>{
                    return <div>{keyword} <CloseOutlined onClick={()=>removeOptionalKeyword(keyword)}/></div>
                  })}
            </div>
           :
            <div className="keywords-nodata" style={{width:'100%',height:380,padding:'0px'}}>
                    <img src={addKeywords}></img>
                    <h3>No keywords yet!</h3>
                    <p>Add specific keywords to improve search accuracy</p>
              
                </div>
            }
        </div>
      

    
      </Modal>

      <Modal
     
        title={<p className="keyword-modal-title">Job Description Editor</p>}
        footer={
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',width:'100%'}} >
            <div style={{width:'max-content'}}>
            <CustomButton text={currentJd?"Regenerate with AI":"Generate with AI"} img={Generate} onClick={generateJdModal} variant="contained"/>
            </div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',gap:'10px'}}>
            <button onClick={()=>{
                if(editJd!=currentJd){ 
                  console.log("edit jd:",editJd )
                  console.log("current jd:",currentJd)
                  showConfirm()
                 
                }
          else{
            setJdModal(false);
            setShow(false);
            setJdEditModal(false);
          }
            }} className="keyword-modal-done">
          Cancel
          </button>
           {!jdLoading? <button onClick={updateJd} className="keyword-modal-done" style={{background:'#1890FF',color:'white',border:'none'}}>
            Save Data 
          </button>:<button className="keyword-modal-done" style={{background:'#1890FF',color:'white',border:'none'}}>
            Save Data  <Spin indicator={<LoadingOutlined spin  style={{color:'white'}}/>} size="small" />
          </button>}
    
            </div>
        
            </div>
         
        }
       
        open={jdModal}
        onCancel={() =>{
          
          if(editJd!=currentJd) showConfirm()
          else{
            setJdModal(false);
            setShow(false);
            setJdEditModal(false);
          }
          }}
        width={'80vw'}
        centered
        destroyOnClose={true}
      >
      <div className="jd-modal">
  
      { show?
         <>
         <EditorToolbar/>
         <ReactQuill
            theme="snow"
            value={editJd}
            onChange={handleChange}
            placeholder={"Write here..."}
            modules={modules}
            formats={formats}
            className="quill-editor"
          
              
          />
        
        
         </>
          :<>
          <h3 class="card-title animated-bg animated-bg-text" id="title">
                &nbsp;
            </h3>
               <h3 class="card-title animated-bg animated-bg-text" id="title" style={{height:'20px'}}>
                &nbsp;
            </h3>
            <p class="card-excerpt" id="excerpt">
                &nbsp;
                <span class="animated-bg animated-bg-text">&nbsp;</span>
                <span class="animated-bg animated-bg-text">&nbsp;</span>
                <span class="animated-bg animated-bg-text">&nbsp;</span>
            </p>
            <h3 class="card-title animated-bg animated-bg-text" id="title" style={{height:'20px'}}>
                &nbsp;
            </h3>
            <p class="card-excerpt" id="excerpt">
                &nbsp;
                <span class="animated-bg animated-bg-text">&nbsp;</span>
                <span class="animated-bg animated-bg-text">&nbsp;</span>
                <span class="animated-bg animated-bg-text">&nbsp;</span>
            </p>
          </>
        
            }
            </div>
      </Modal>

      {isModalOpen && (<CustomModal show={isModalOpen} handleClose={handleCloseModal} showExperince={showExperince} />)}

      {jobEditModal && <JobOverviewEditModal editModal={jobEditModal} setEditModal={setJobEditModal} data={vacancyData}     fetchJobData={fetchJobData}/>}
        </>

       
        
    )
}
export default JobOverview