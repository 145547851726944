import React from 'react';
import "./renderResults.css"


const RenderResults = ({options}) => {
  
  return (
    <div >
      {options.map((option, index) => (
        <div
          key={index}
          
          className='renderResults'
        >
          
        <div style={{color: parseFloat(option.percentage.replace("%", ""))>50 ?'#52C41A':'#F5222D'}}> {option.percentage}</div>
        <div className='renderResultsCriteria'>
           <div >
                {option.title}
            </div>
            <div>
           {option.description}
            </div>
        </div>
        </div>
      
      ))}
    </div>
  );
};

export default RenderResults;
