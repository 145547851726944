import React, { useEffect } from "react";
import "./overviewCard.css"
import { useVacancyStore } from "./store/store";


const OverviewCard = () =>{

    const {vacancyCreatorFlag,responseData} = useVacancyStore(state=>state);

 
    const statusOptions = [
    {
        name:"Draft",
        color: "#909090",
        value: responseData?.count?.draftCount,
        key:"draftCount"
    },
    {
        name:"Pending",
        color: "#FA8C16",
        value: responseData?.count?.pendingCount,
        key:"pendingCount"
    },
    {
        name:"Activated",
        color: "#52C41A",
        value: responseData?.count?.activatedCount,
        key:"activatedCount"
    },
    {
        name:"Denied",
        color: "#F5222D",
        value: responseData?.count?.deniedCount,
        key:"deniedCount"
    },
];

let allRequestCount=0;
statusOptions?.forEach(status=>{
    status.value=  responseData?.count?.[status.key];
    allRequestCount+=responseData?.count?.[status.key]||0;
  })

const filteredStatusOptions = vacancyCreatorFlag===false
? statusOptions.filter(option => option.name !== "Draft")
: statusOptions;

    

    return (
        <div className="overviewCard-container">
            <div>OVERVIEW</div>
            <div  style={{marginBottom:'2rem'}}>
                <div className="spanClass">{allRequestCount||'-'}</div> 
                <div>{allRequestCount>1?'Requests':'Request'}</div>
            </div>
            
            {filteredStatusOptions.map((option, index) => (
                <div>
                <div key={index} className="overviewCard-contents" >
                   <div className="overviewCard-subcontents1">
                        <span style={{ backgroundColor: option.color, width: '14px', height: '14px', borderRadius: '2px' }}></span>
                        <span>{option.name}</span>
                    </div>
                    <div className="overviewCard-subcontents2">
                        <span>{option.value||'-'}</span>
                    </div>
                 
                </div>
                {(index!==filteredStatusOptions.length-1)&&<hr style={{border: '1px solid #E9E9E9' ,width:'100%', marginTop:'1.5rem', marginBottom:'1.5rem'}}></hr>}
                </div>
              
            ))}
          
        </div>
    );
}

export default OverviewCard;